import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  mobile_modal_paper: {
    maxHeight: "none",
    height: "100vh",
    overflow: "hidden",
  },
  desktop_modal_paper: {
    maxWidth: "unset",
    width: "80%",
    minHeight: "300px",
  },
  mobile_header: {
    display: "flex",
    alignItems: "center",
    padding: "12px 19px 12px 16.49px",
    borderBottom: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
    height: "95.5px",
    boxSizing: "border-box",
  },
  details_activity_wrapper: {
    height: "80vh",
    overflow: "scroll",
  },
  mobile_header_details: {
    height: "70px",
    boxSizing: "border-box",
    overflow: "hidden",
    margin: "0 16px 0 8px",
    flexGrow: 1,
    color: "rgba(0, 0, 0, 0.85)",
    fontSize: "14px",
    lineHeight: "17px",
  },
  ellipsis: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  customer_name: {
    height: "20px",
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 700,
  },
  mobile_customer_email: {
    height: "17px",
    marginBottom: "8px",
    marginTop: "8px",
    color: theme?.palette?.primary?.main,
    display: "block",
  },
  desktop_customer_email: {
    color: theme?.palette?.primary?.main,
    textDecoration: "underline",
    fontSize: "14px",
    height: "17px",
  },
  phone_number: {
    height: "17px",
    fontSize: "14px",
    lineHeight: "17px",
    fontWeight: 400,
  },
  desktop_phone_number: {
    marginRight: "8px",
  },
  mobile_header_actions: {
    display: "flex",
    alignItems: "center",
    gap: "27.02px",
    paddingTop: "4px",
  },
  mobile_whatsapp_icon_wrapper: {
    padding: "0",
  },
  mobile_whatsapp_icon: {
    width: "28px",
    maxHeight: "28px",
  },
  mobile_close_icon_wrapper: {
    padding: 0,
    "&:hover": {
      background: theme?.palette?.basic?.white,
    },
  },
  mobile_close_icon: {
    color: theme?.palette?.secondary?.main,
    width: "18.94px",
    height: "18.94px",
  },
  mobile_content: {
    overflowY: "auto",
    height: "calc(100vh - 95.5px)",
  },
  desktop_header: {
    padding: "16.5px 24px 16px 24px",
    borderBottom: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
    display: "flex",
    gap: "12.5px",
    alignItems: "center",
  },
  flexGrow: {
    flexGrow: 1,
  },
  desktop_header_avatar: {
    width: "40px",
    height: "40px",
    fontSize: "16px",
    fontWeight: 700,
  },
  desktop_contact_section: {
    display: "flex",
    alignItems: "flex-end",
  },
  divider: {
    width: "1.5px",
    height: "16px",
    marginLeft: "8.49px",
    marginRight: "8.5px",
    background: "#C4C4C4",
  },
  tabs_scroller: {
    paddingBottom: 0,
    border: "none",
    margin: ({ isDesktop }) =>
      isDesktop ? "20.5px 24px 0 24px" : "20.5px 16px 0 16px",
  },
  selected_tab_title: {
    margin: ({ isDesktop }) =>
      isDesktop ? "20.5px 24px 0 24px" : "20.5px 16px 0 16px",
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 500,
    color: theme?.palette?.secondary?.main,
  },
}));
