import React, { useState, useEffect } from "react";
import { downloadCSV } from "react-admin";
import Backdrop from "@material-ui/core/Backdrop";
import Modal from "@material-ui/core/Modal";

// Styles and icons
import EmptyListIcon from "assets/vectors/infoModal/Ad_Leads.svg";

// Utils and providers
import { is_empty, fixCountryCode } from "../../../../../utils/validations";
import constants from "../../../../../constants/constants";
import { orgPermissions } from "../../../../../utils/OrgPermissions";
import {
  tableConfig,
  filterConfig,
  tableChipsFormatter,
} from "./AdLeadsTableConfig";
import jsonExport from "jsonexport/dist";

// Components
import Share from "../../../../modules/Share";
import Messages from "../Messages";
import ExlyTable from "common/Components/ExlyTable";
import { WhatsAppButton } from "webpage-leads/components/CustomColumns";
import { useNotifications } from "utils/hooks";
import useFeaturesActivationStatus from "features/FeatureActivationStatus/utils/useFeaturesActivationStatus";
import { feature_activation_keys } from "features/FeatureActivationStatus/FeatureActivationStatus.constants";
import { getCreatorHostsiteURL } from "features/Account/utils/getCreatorPageDetails";

// TODO: Empty State UI Pending
const FilteredEmptyState = () => {
  return (
    <div className="text-center py-5 text-muted">
      <div>No leads found!</div>
    </div>
  );
};

// Main Component
const AdLeads = (props) => {
  const [showTable, setShowTable] = useState(false);
  const [show_msgs, set_show_msgs] = useState(false);
  const [msgs_data, set_msgs_data] = useState(null);
  const [record_data, set_record_data] = useState(null);
  const [showShare, setShowShare] = useState(false);

  const { pushNotification } = useNotifications();
  const { loading, featuresActivationStatus } = useFeaturesActivationStatus();

  useEffect(() => {
    setShowTable(
      featuresActivationStatus[feature_activation_keys.enable_leadgen.key]
    );
  }, [loading]);

  const exporter = (data) => {
    const dataForExport = data.map((data) => {
      let countryCode = fixCountryCode(data?.country);
      let return_data = {
        "Customer Name": data.full_name,
        "Phone Number": !is_empty(data.phone_number)
          ? `${countryCode} ${data.phone_number}`
          : `N/A`,
        Email: data.email,
        "Reason of contact": data.reason,
      };
      // const { uuid, author, ...postForExport } = post; // omit backlinks and author
      // postForExport.author_name = post.author.name; // add a field
      return return_data;
    });
    jsonExport(
      dataForExport,
      {
        headers: [
          "Customer Name",
          "Phone Number",
          "Email",
          "Reason of contact",
          "Last contacted on",
        ], // order fields in the export
      },
      (err, csv) => {
        downloadCSV(csv, "Ad Leads");
      }
    );
  };

  const emptyStateProps = {
    page_title: "Ad Leads",
    content_title: "Oops, No leads captured yet",
    empty_list_icon: EmptyListIcon,
    alt: "no leads",
    primary_cta: {
      children: "Share Your webpage",
      onClick: () => setShowShare(true),
    },
    secondary_cta: { children: "View sample data" },
    preview_image: constants.preview_ad_leads,
    children: `Details of people who want to get in touch with you or are in interested
      in any of your offerings will show here`,
  };

  return (
    <div className="component-wrapper">
      <ExlyTable
        ra_props={{
          ...props,
          exporter: orgPermissions.canExport() ? exporter : false,
        }}
        noExportButton={!orgPermissions.canExport()}
        layoutProps={{
          paddingDesktop: "0",
          paddingMobile: "0",
          paddingBottom: "120px",
          noMobileBoxShadow: true,
        }}
        forceEmptyState={!showTable}
        tableTitle="Ad Leads"
        columnConfig={tableConfig}
        tableFilters={filterConfig}
        tableChipsFormatter={tableChipsFormatter}
        filteredEmptyState={<FilteredEmptyState />}
        renderPrimaryColumnRightNode={(record) => {
          return (
            <>
              {is_empty(record.customer_phone) ? null : (
                <WhatsAppButton
                  record={record}
                  source="phone_number"
                  countryCodeKey="country_code"
                />
              )}
            </>
          );
        }}
        borderedFields
        drawerFieldsBordered
        primaryKey="id"
        {...emptyStateProps}
      />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showShare}
        onClose={() => setShowShare(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Share
          toast={(text) => {
            pushNotification(text, { variant: "outlined", color: "primary" });
          }}
          header={"Share your webpage"}
          link={getCreatorHostsiteURL().url}
          title={"Have a look at my webpage, "}
          setShowShare={(value) => {
            setShowShare(value);
          }}
        />
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={show_msgs}
        onClose={() => set_show_msgs(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Messages
          data={{ msgs_data: msgs_data, record_data: record_data }}
          close={() => {
            set_msgs_data(null);
            set_record_data(null);
            set_show_msgs(false);
          }}
        />
      </Modal>
    </div>
  );
};

export default AdLeads;
