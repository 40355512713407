import React from "react";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";

import ExlyModal from "common/Components/ExlyModal";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import SyncProblemRoundedIcon from "@material-ui/icons/SyncProblemRounded";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import classnames from "classnames";
import styles from "./BulkImportModal.module.css";

const BulkImportModal = (props) => {
  const {
    open,
    handleClose,
    titleText = "Bulk import",
    descriptionText,
    successMessage,
    errorMessage,
    warningMessage,
    secondButtonText,
    handlePrimaryButtonClick,
    handleSecondaryButtonClick,
    disablePrimaryButton = false,
    disableSecondaryButton = false,
    showWarningStrip = false,
  } = props;

  return (
    <ExlyModal
      open={open}
      onClose={handleClose}
      className={styles.modal_style}
      title={<div className={styles.header_modal}>{titleText}</div>}
      primaryBtnText={
        <div>
          <GetAppRoundedIcon className={styles.button_one_icon} />
          Download report
        </div>
      }
      showSecondaryBtn={Boolean(secondButtonText)}
      primaryBtnFullWidth={!secondButtonText}
      onPrimaryBtnClick={handlePrimaryButtonClick}
      secondaryBtnText={secondButtonText}
      onSecondaryBtnClick={handleSecondaryButtonClick}
      primaryBtnClassName={styles.button_class_name}
      secondaryBtnClassName={styles.button_class_name}
      primaryBtnProps={{
        buttonWrapperClassName: styles.button_class_name,
        disabled: disablePrimaryButton,
      }}
      secondaryBtnProps={{
        buttonWrapperClassName: styles.button_class_name,
        variant: "outlined",
        color: "secondary",
        disabled: disableSecondaryButton,
      }}
    >
      <div className={styles.body_modal}>
        <div className={styles.info_container}>
          <div className={classnames(styles.info_box, styles.success_box)}>
            <CheckCircleOutlineRoundedIcon />
            <span>{successMessage}</span>
          </div>
          <div className={classnames(styles.info_box, styles.error_box)}>
            <HighlightOffRoundedIcon />
            <span>{errorMessage}</span>
          </div>
          {warningMessage && (
            <div className={classnames(styles.info_box, styles.warning_box)}>
              <SyncProblemRoundedIcon />
              <span>{warningMessage}</span>
            </div>
          )}
        </div>
        <div className={styles.body_modal_text}>
          {descriptionText ||
            "Download the report to check out all the information about imports, including why some contacts couldn't be added"}
        </div>
        {showWarningStrip ? (
          <div className={styles.info_warning_style}>
            <InfoRoundedIcon className={styles.info_warning_icon_style} />
            <div className={styles.info_warning_text_style}>
              It will take some time for the imported tags (if any) to be
              visible on your contacts.
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </ExlyModal>
  );
};

export default BulkImportModal;
