// Third party imports
import { crmConstant } from "constants/crmConstants";
import React from "react";

// Utils and providers
import { getUserCurrencySymbol } from "../../../../../../../utils/AuthUtil";

export const ActivityTypeField = ({ record = {}, source }) => {
  const { creator_sales } = record || {};
  switch (record[source]) {
    case crmConstant.logsType.BOUGHT_OFFERING:
      return (
        <div>{`Made a booking/purchase of ${getUserCurrencySymbol()} ${creator_sales?.toFixed(
          2
        )}`}</div>
      );
    case crmConstant.logsType.PAYMENT_FAILED:
      return (
        <div>{`Failed payment attempt of ${getUserCurrencySymbol()} ${creator_sales?.toFixed(
          2
        )}`}</div>
      );
    case crmConstant.logsType.GET_IN_TOUCH:
      return <div>{`Wants to be contacted`}</div>;
    case crmConstant.logsType.PAYMENT_PENDING:
      return <div>{`Expressed Interest in payment`}</div>;
    case crmConstant.logsType.SUBSCRIBED_OFFERING:
      return (
        <div>{`Subscribed for ${getUserCurrencySymbol()} ${creator_sales?.toFixed(
          2
        )}`}</div>
      );
    case crmConstant.logsType.RENEWED_SUBSCRIPTION:
      return (
        <div>{`Renewed subscription, paid ${getUserCurrencySymbol()} ${creator_sales?.toFixed(
          2
        )}`}</div>
      );
    default:
      return "N/A";
  }
};
