import React from "react";
import classes from "./ImportClients.module.css";

export const import_type = {
  lead: "lead",
  customer: "customer",
};

export const UPLOAD_ERROR_MSG = {
  default: "Error Out of Bounds!",
};

// Selecting title of the tooltip based on question type
export const toolTipTitle = (
  <div className={classes.tooltip}>
    <span className={classes.bold}>Intellegent column mapping:</span> means we
    will recognize column headers in your csv file and then organize the data
    accordingly.
    <br></br>
    <br></br>
    For Exly to organize your leads best, we recommend you to prepare your csv
    file by labelling the column headings in the spreadsheet’s first row.
  </div>
);

export const api_import_type = {
  [import_type.lead]: 1,
  [import_type.customer]: 2,
};

export const sample_file_download = {
  [import_type.lead]: "creator-tool/bulk_import/sample_bulk_import_leads.csv",
  [import_type.customer]: {
    subscription_csv:
      "creator-tool/bulk_import/sample_bulk_import_customers_subs.csv",
    subscription_gst_csv:
      "creator-tool/bulk_import/sample_bulk_import_customers_subs_gst.csv",
    no_subscription_csv:
      "creator-tool/bulk_import/sample_bulk_import_customers_no_subs.csv",
    no_subscription_gst_csv:
      "creator-tool/bulk_import/sample_bulk_import_customers_no_subs_gst.csv",
    part_payment_csv:
      "creator-tool/bulk_import/sample_bulk_import_customers_part_payment.csv",
    part_payment_gst_csv:
      "creator-tool/bulk_import/sample_bulk_import_customers_part_payment_gst.csv",
  },
};

export const redirect_route = {
  [import_type.lead]: "/host/v2/interest/list",
  [import_type.customer]: "/payments/exly/customers/list/v2",
};
