// Third party imports
import React from "react";
import moment from "moment";

// Utils and providers
import constants from "../../../../../constants/constants";
import api from "../../../../../data/APIs";

// Components
import { ActivityTypeField } from "./components/TableFields/TableFields";
import { ActionField } from "common/Components/TableFields/TableFields";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Tooltip } from "@my-scoot/component-library-legacy";
import classNames from "./customerActivityV2Styles.module.css";
import { is_empty } from "utils/validations";
import { customer_activity_form_data } from "./customerActivityV2Constants";
import { isNumberType } from "common/validate";
import { DateConvert } from "utils/dateTimeUtils";

export const getTableConfig = ({
  onClickViewDetails,
  customColumnsToRender = [],
}) => [
  {
    field: customer_activity_form_data.offering_name.field,
    headerName: customer_activity_form_data.offering_name.placeHolder,
    emptyField: "N/A",
    sortable: false,
    width: "200px",
    valueFormatter: (record) =>
      `${record[customer_activity_form_data.offering_name.field]} ${
        record.offering_variant_uuid
          ? ` - ${record.offering_variant_title}`
          : ""
      }`,
  },
  {
    field: customer_activity_form_data.offering_type.field,
    headerName: customer_activity_form_data.offering_type.placeHolder,
    valueFormatter: (record) =>
      isNumberType(record.type)
        ? constants.schedule_type.filter((item) => item.id === record.type)[0]
            .name
        : "N/A",
    sortable: false,
    noWrap: true,
  },
  {
    field: customer_activity_form_data.offering_owner.field,
    headerName: (
      <>
        {customer_activity_form_data.offering_owner.placeHolder}
        <Tooltip
          title="This has been assigned from member selected in the offering "
          color="primary"
          arrow
        >
          <InfoOutlinedIcon className={classNames.infoIconStyle} />
        </Tooltip>
      </>
    ),
    valueFormatter: (record) =>
      is_empty(record.staff_name) ? "N/A" : record.staff_name,
    sortable: false,
    isCollapsed: true,
    width: "70px",
  },
  {
    field: customer_activity_form_data.status.field,
    headerName: customer_activity_form_data.status.placeHolder,
    valueFormatter: (record) => (
      <ActivityTypeField
        record={record}
        source={customer_activity_form_data.status.field}
      />
    ),
    sortable: false,
  },
  {
    field: customer_activity_form_data.date.field,
    headerName: customer_activity_form_data.date.placeHolder,
    valueFormatter: (record) =>
      record.created_at
        ? isNumberType(record.created_at)
          ? moment
              .unix(record.created_at)
              .format(`${constants.displayDateYearFormat}, hh:mm${"\u00A0"}A`)
          : DateConvert(record[customer_activity_form_data.date.field], true)
        : "N/A",
    sortable: false,
    noWrap: true,
  },
  ...customColumnsToRender,
  {
    field: "",
    headerName: "Action",
    valueFormatter: (record) => (
      <ActionField
        label="View Details"
        record={record}
        onClick={onClickViewDetails}
      />
    ),
    sortable: false,
    noWrap: true,
    hidden: true,
    fixed: "right",
  },
];

export const CUSTOMER_ACTIVITY_PROPS = {
  basePath: `/${api.customer_activity}`,
  history: {
    length: 6,
    action: "POP",
    location: {
      pathname: `/${api.customer_activity}`,
      search: "",
      hash: "",
    },
  },
  location: { pathname: `/${api.customer_activity}`, search: "", hash: "" },
  match: {
    path: `/${api.customer_activity}`,
    url: `/${api.customer_activity}`,
    isExact: true,
    params: {},
  },
  resource: `${api.customer_activity}`,
  options: { label: `${api.customer_activity}` },
  hasList: true,
  hasEdit: false,
  hasShow: false,
  hasCreate: false,
  syncWithLocation: false,
  exporter: false,
};
