import React from "react";
import MoneyField from "ui/modules/MoneyField";
import { DateConvert } from "utils/Utils";
import { ActionField } from "common/Components/TableFields/TableFields";
import api from "../../../../../data/APIs";

export const getTableConfig = ({
  onChangeExpiryDate,
  hasWriteAccess,
  isBrandedCommunity,
}) => [
  {
    field: "session_name",
    headerName: isBrandedCommunity ? "Community Name" : "Content name",
    emptyField: "N/A",
    sortable: false,
  },
  {
    field: "creator_sales",
    headerName: "Price paid",
    valueFormatter: (record) => (
      <MoneyField record={record} source="creator_sales" />
    ),
    sortable: false,
  },
  {
    field: "purchase_date",
    headerName: "Purchase date",
    valueFormatter: (record) => DateConvert(record, "purchase_date"),
    sortable: false,
  },
  {
    field: "expiry_date",
    headerName: "Expiry date",
    valueFormatter: (record) =>
      record.expiry_date ? DateConvert(record, "expiry_date") : "N/A",
    sortable: false,
  },
  {
    field: "",
    headerName: "Action",
    sortable: false,
    valueFormatter: (record) => (
      <ActionField
        label="Change Expiry date"
        record={record}
        onClick={onChangeExpiryDate}
      />
    ),
    hidden: true,
    hiddenDesktopColumn: !hasWriteAccess,
  },
];

export const CONTENT_TRANSACTION_PROPS = (listingtype) => ({
  basePath: `/${api.content_purchases_list}`,
  history: {
    length: 6,
    action: "POP",
    location: {
      pathname: `/${api.content_purchases_list}`,
      search: "",
      hash: "",
    },
  },
  location: {
    pathname: `/${api.content_purchases_list}`,
    search: "",
    hash: "",
  },
  match: {
    path: `/${api.content_purchases_list}`,
    url: `/${api.content_purchases_list}`,
    isExact: true,
    params: {},
  },
  filterDefaultValues: { listing_type: listingtype },
  resource: `${api.content_purchases_list}`,
  options: { label: `${api.content_purchases_list}` },
  hasList: true,
  hasEdit: false,
  hasShow: false,
  hasCreate: false,
  syncWithLocation: false,
});
