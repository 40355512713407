import React, { useEffect, useState } from "react";

import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import FilterImg from "assets/vectors/crm/filter_list.svg";
import ExlyImage from "common/Components/ExlyImage";
import {
  notification_color_keys,
  useNotifications,
  useToggleState,
} from "utils/hooks";
import AddCustomFilterModal from "webpage-leads/components/CrmModals/AddCustomFilterModal/AddCustomFilterModal";
import MobileFilterActive from "assets/vectors/crm/mobile_filter_active.svg";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import Popover from "@material-ui/core/Popover";

import {
  createSegment,
  getAllTags,
  setCustomFiltersToSessionStorage,
  updateSegment,
} from "webpage-leads/webpageHelpers";
import { is_empty } from "utils/validations";
import { api, dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import { filtersValueMapping } from "webpage-leads/components/CrmModals/AddCustomFilterModal/addCustomFilterModalHelpers";
import constants from "constants/constants";
import useStyles from "./customerFiltersStyle.styles";
import {
  BreadrCrumb,
  EmptyFilterState,
  FiltersToApply,
} from "./FiltersComponents";
import { segmentConstants } from "constants/segmentConstants";
import { ALL, filterTypesMapping } from "../../customerConstants";
import { useRefresh } from "react-admin";
import { useDispatch } from "react-redux";
import { EXLY_GLOBAL__SET_SHOW_PAGE_TITLE } from "redux/actions";
import {
  getQuestionsArray,
  parseCustomFilterValue,
} from "./customFiltersHelpers";
import { OFFERING_STATUSES } from "constants/schedule";

/**
 *
 * Main Component is Written Here
 */
const CustomFiltersCustomer = ({
  filterValues,
  setCustomFiltersValue,
  customFiltersValue,
  segmentId,
  setSegmentId,
  setFilters,
  clearFilters,
  selectedFilter,
  hasChips,
}) => {
  /**
   * Setters Section
   */
  const [openFiltersModal, setOpenFiltersModal, setCloseFiltersModal] =
    useToggleState(false);
  const entityType = constants.entity_type.customers.value;
  const [isFilterPopover, setFilterPopover] = useState(false);
  const [filterToEdit, setFilterToEdit] = useState(null);
  const [questionsArray, setQuestionsArray] = useState([]);
  const [existingOffers, setExistingOffers] = useState([]);
  const [selectedListing, setSelectedListing] = useState(null);
  const [filterVal, setFilterVal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tagsArray, setTagsArray] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [answersArray, setAnswersArray] = useState([]);

  const handleClick = (event) => {
    if (isFilterDisabled) return null;

    setAnchorEl(event.currentTarget);
  };
  const dispatch = useDispatch();
  const { notify } = useNotifications();

  const handleClose = () => {
    setAnchorEl(null);
  };
  const refetch = useRefresh();

  const isDesktop = useDesktopMediaQuery();
  const isFilterDisabled =
    filterValues !== ALL ||
    (selectedFilter && selectedFilter.value !== filterTypesMapping.select) ||
    hasChips;
  const classes = useStyles({ isDesktop, isFilterDisabled });

  useEffect(() => {
    dispatch({
      type: EXLY_GLOBAL__SET_SHOW_PAGE_TITLE,
      payload: !isFilterPopover,
    });
  }, [isFilterPopover]);

  /********************************Setter ***************/

  /**** UseEffects ********************** */
  //tagsArray's value is getting used by child components irrespective of the filter value
  // hence the api needs to be called in did mount, putting a comment for that
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await getAllTags();
        const parsedArray = [];

        // setting tags if there are tags
        !is_empty(data) &&
          data.forEach((value) => {
            const localObj = {
              label: value?.name,
              value: value?.uuid,
            };

            parsedArray.push(localObj);
          });
        setTagsArray(parsedArray);
      } catch (err) {
        console.log(err);
        notify(`error while getting tags`, notification_color_keys.error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);
  useEffect(() => {
    if (filterVal === filtersValueMapping.answer_to_booking_questions) {
      (async () => {
        try {
          setLoading(true);
          // only filter out listing which have questions

          const { data } = await dataProvider.custom_request(
            `${api.fetch_offerings}`,
            apiMethods.GET,
            {
              show_listing_having_questions_with_choices: true,
              status: OFFERING_STATUSES.live.value,
              page: "all",
            }
          );
          if (data) {
            const listingsData = data?.listings || [];
            setExistingOffers(
              listingsData.map((listing) => ({
                ...listing,
                id: listing.uuid,
                label: listing.title,
                value: listing.uuid,
                type: listing.type,
              }))
            );
          }
        } catch (err) {
          console.log(err);
          notify(`error while getting listings`, notification_color_keys.error);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [filterVal]);

  useEffect(() => {
    if (selectedListing) {
      (async () => {
        try {
          const parsedArray = await getQuestionsArray(selectedListing);
          setQuestionsArray(parsedArray);
        } catch (err) {
          console.log(err || "error while setting question");
          notify(`error while setting question`, notification_color_keys.error);
        }
      })();
    }
  }, [selectedListing]);

  /**** UseEffects ********************** */

  /*****************   Filters Functions  *************** */

  const openAddFiltersModal = () => {
    setOpenFiltersModal();
  };

  const openEditFiltersModal = (value, idx) => {
    setFilterToEdit({ ...value, idx: idx });
    setOpenFiltersModal();
  };

  /**
   * Function to apply custom filters by creating a segment
   */
  const applyFilters = async () => {
    try {
      setLoading(true);
      if (segmentId) {
        const payload = {
          updated_values: {
            filters: parseCustomFilterValue(customFiltersValue),
          },
        };
        const response = await updateSegment(segmentId, payload); //update segment call
        if (response.status === 200) {
          //Reseting segment to previous segment and calling the filter again
          // This is done because the same segment has been updated
          setSegmentId(segmentId);
          setFilters({ segment_uid: segmentId });
          refetch();
        }
      } else {
        const payload = {
          title: segmentConstants.DEFAULT_CUSTOM_FILTER,
          filters: parseCustomFilterValue(customFiltersValue),
          view_filters: "",
          target: entityType,
          combining_filter: segmentConstants.AND,
          is_active: false,
        };
        const response = await createSegment(payload); //create segment call
        if (response.status === 200) {
          setSegmentId(response?.data?.segment_uid);
          setFilters({ segment_uid: response?.data?.segment_uid });
        }
      }
    } catch (err) {
      console.log("error while applying filters");
    } finally {
      handleClose();
      setFilterPopover(false);
      setLoading(false);
    }
  };

  /**
   *
   * @param {*} value
   * @param {*} questionsObj
   * @param {*} index
   * @returns Function to add a filter to custom filters array
   */
  const addFilters = (value, questionsObj, index) => {
    const localCustomFiltersValue = [...customFiltersValue];
    if (index !== null) {
      // removing the old filter for edit case
      localCustomFiltersValue.splice(index, 1);
    }
    if (value?.value.length === 0) {
      setCloseFiltersModal();
      return;
    }
    if (!is_empty(questionsObj)) {
      const localValue = {
        ...value,
        ...questionsObj,
      };

      localCustomFiltersValue.push(localValue);
    } else {
      localCustomFiltersValue.push(value);
    }
    setCustomFiltersToSessionStorage(localCustomFiltersValue);

    setCustomFiltersValue(localCustomFiltersValue);

    setCloseFiltersModal();
  };

  /**
   *
   * @param {*} idx
   * Function to delete filters from custom filters array
   */
  const handleDelete = (idx) => {
    setDeleteLoader(true);
    let localCustomFiltersValue = [...customFiltersValue];
    localCustomFiltersValue.splice(idx, 1);
    setCustomFiltersToSessionStorage(localCustomFiltersValue);
    setCustomFiltersValue(localCustomFiltersValue);
    // clearing all the filters when deleting the last custom filter
    if (localCustomFiltersValue.length === 0) {
      clearFilters();
      handleClose();
    }
    setDeleteLoader(false);
  };

  // For poper toggle
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  /**
   *
   * @returns Mobile view of filters
   */
  const returnMobileViewComponent = () => {
    return isFilterPopover ? (
      <div className={classes.mobileFiltersWrapper}>
        <header>
          <BreadrCrumb
            title={"Custom Filters"}
            setFilterPopover={setFilterPopover}
          />
        </header>
        {!is_empty(customFiltersValue) ? (
          <FiltersToApply
            setOpenFiltersModal={() => openAddFiltersModal()}
            isDesktop={isDesktop}
            customFiltersValue={customFiltersValue}
            applyFilters={applyFilters}
            handleDelete={handleDelete}
            tagsArray={tagsArray}
            openEditFiltersModal={openEditFiltersModal}
          />
        ) : (
          <>
            <EmptyFilterState
              setOpenFiltersModal={setOpenFiltersModal}
              classes={classes}
              isDesktop={isDesktop}
            />
          </>
        )}{" "}
      </div>
    ) : (
      <div
        onClick={() => {
          if (isFilterDisabled) return;
          setFilterPopover(true);
        }}
      >
        <ExlyImage
          src={MobileFilterActive}
          alt={"MobileFilterActive"}
          className={classes.filterMobileFilter}
        />
        {customFiltersValue?.length > 0 ? (
          <div className={classes.filterNumbers}>
            {customFiltersValue.length}
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  return (
    <>
      {!isDesktop ? (
        <>{returnMobileViewComponent()} </>
      ) : (
        <>
          {" "}
          <div>
            <div className={classes.newFilterWrapper} onClick={handleClick}>
              {" "}
              {/** only making the popover clickable when All tabs in selected */}
              <ExlyImage src={FilterImg} alt={"FilterImg"} />
              Advance Filters
              {!is_empty(customFiltersValue) ? (
                <div className={classes.filterNumbersDesktop}>
                  {customFiltersValue.length}
                </div>
              ) : (
                <></>
              )}
            </div>

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              {!is_empty(customFiltersValue) ? (
                <FiltersToApply
                  setOpenFiltersModal={() => openAddFiltersModal()}
                  isDesktop={isDesktop}
                  customFiltersValue={customFiltersValue}
                  applyFilters={applyFilters}
                  handleDelete={handleDelete}
                  tagsArray={tagsArray}
                  openEditFiltersModal={openEditFiltersModal}
                  loading={deleteLoader}
                />
              ) : (
                <EmptyFilterState
                  setOpenFiltersModal={setOpenFiltersModal}
                  classes={classes}
                  isDesktop={isDesktop}
                  entityType={entityType}
                />
              )}
            </Popover>
          </div>
        </>
      )}
      <AddCustomFilterModal
        open={openFiltersModal}
        onClose={setCloseFiltersModal}
        onSubmit={addFilters}
        setCustomFiltersValue={setCustomFiltersValue}
        customFiltersValue={customFiltersValue}
        entityType={entityType}
        filterToEdit={filterToEdit}
        setFilterToEdit={setFilterToEdit}
        setSelectedListing={setSelectedListing}
        questionsArray={questionsArray}
        existingOffers={existingOffers}
        selectedListing={selectedListing}
        filterVal={filterVal}
        setFilterVal={setFilterVal}
        loading={loading}
        tagsArray={tagsArray}
        selectedQuestion={selectedQuestion}
        setSelectedQuestion={setSelectedQuestion}
        answersArray={answersArray}
        setAnswersArray={setAnswersArray}
      />{" "}
    </>
  );
};

export default withComponentLibraryTheme(CustomFiltersCustomer);
