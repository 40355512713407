// Third party imports
import React from "react";

import { WhatsAppPhoneField } from "common/Components/TableFields/TableFields";

export const form_data = {
  start_date: "Start Date",
  phone: "Phone",
  name: "Name",
  session_name: "Demo Session Name",
  email: "Email Id",
  reason: "Reason for contact",
  action: "Message(s)",
  date: "Last contacted on",
  action_text: "See all messages",
};

export const tableConfig = [
  {
    field: "full_name",
    headerName: "Customer Name",
    emptyField: "N/A",
    isPrimary: true,
    sortable: false,
    ellipsis: true,
    avatar: true,
    columnClassName: "word_break",
    width: "200px",
  },
  {
    field: "email",
    headerName: "Email Id",
    sortable: false,
    width: "150px",
    columnClassName: "word_break",
  },
  {
    field: "phone_number",
    headerName: "Phone Number",
    valueFormatter: (record) => (
      <WhatsAppPhoneField
        record={record}
        source="phone_number"
        countryCodeKey="country"
      />
    ),
    sortable: false,
  },
  {
    field: "reason",
    headerName: form_data.reason,
    emptyField: "N/A",
    sortable: false,
  },
];

export const filterConfig = [
  {
    source: "full_name",
    placeholder: form_data.name,
    type: "input",
  },
  {
    source: "email",
    placeholder: form_data.email,
    type: "input",
  },
  {
    source: "phone_number__contains",
    placeholder: form_data.phone,
    type: "input",
    disableContains: true,
  },
];

export const tableChipsFormatter = (filter, value) => {
  switch (filter) {
    case "full_name__icontains":
      return `Name: ${value}`;
    case "email__icontains":
      return `Email: ${value}`;
    case "phone_number__contains":
      return `Phone: ${value}`;
    default:
      return undefined;
  }
};
