// Third party imports
import React from "react";

import {
  DateTimeField,
  WhatsAppPhoneField,
} from "common/Components/TableFields/TableFields";
import { LinkField } from "webpage-leads/components/CustomColumns";
import { masterGetBroadcastActions } from "../../ManageBookings/components/MasterTableConfig";

export const masterFormData = {
  name: "Customer Name",
  timezone: "Timezone",
  email: "Customer Email",
  phone: "Phone",
  shipping_status: "Shipping Status",
  booked_on: "Date",
  slots_booked: "Slots booked",
  batch: "Batch",
  upsell_type: "Promotion Type",
  clear: "Clear Filter",
  start_date: "Start Date",
  end_date: "End Date",
  week: [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ],
  course_end_type: ["", "week", "month", "session"],
  type_cta: [""],
};

export const masterGetTableConfigDefault = ({ handleAnswersClick }) => [
  {
    field: "customer_name",
    headerName: masterFormData.name,
    emptyField: "N/A",
    isPrimary: true,
    sortable: false,
    ellipsis: true,
    avatar: true,
    columnClassName: "word_break",
    width: "200px",
  },
  {
    field: "customer_email",
    headerName: masterFormData.email,
    sortable: false,
    width: "150px",
    columnClassName: "word_break",
  },
  {
    field: "customer_phone",
    headerName: masterFormData.phone,
    valueFormatter: (record) => (
      <WhatsAppPhoneField
        record={record}
        source="customer_phone"
        countryCodeKey="country"
      />
    ),
    sortable: false,
  },
  {
    field: "booked_on",
    headerName: masterFormData.booked_on,
    valueFormatter: (record) => (
      <DateTimeField record={record} source="booked_on" />
    ),
    sortable: false,
    noWrap: true,
  },
  {
    field: "customer_timezone",
    headerName: masterFormData.timezone,
    emptyField: "N/A",
    sortable: false,
    hidden: true,
  },
  {
    field: "answer_count",
    headerName: "Check Answers",
    valueFormatter: (record) =>
      record.answer_count ? (
        <LinkField
          record={record}
          source="answer_count"
          linkText="View Answers"
          onClick={handleAnswersClick}
        />
      ) : (
        <div className="mr-12">No Answers</div>
      ),
    noWrap: true,
    sortable: false,
    hidden: true,
  },
];

export const filterConfig = [
  {
    source: "guest_name",
    placeholder: masterFormData.name,
    type: "input",
    disableContains: true,
  },
  {
    source: "user__email__icontains",
    placeholder: masterFormData.email,
    type: "input",
    disableContains: true,
  },
  {
    source: "user__phone_number__icontains",
    placeholder: masterFormData.phone,
    type: "input",
    disableContains: true,
  },
];

export const tableChipsFormatter = (filter, value) => {
  switch (filter) {
    case "guest_name":
      return `${masterFormData.name}: ${value}`;
    case "user__email__icontains":
      return `customer_email: ${value}`;
    case "user__phone_number__icontains":
      return `Phone: ${value}`;
    default:
      return undefined;
  }
};

export const getBroadcastActions = (props) => {
  return masterGetBroadcastActions(props);
};
