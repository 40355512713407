import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  mobile_listing_wrapper: {
    padding: "0 16px 16px 16px",
    borderBottom: "none",
    marginBottom: "112px",
  },
  desktop_actions: {
    display: "flex",
    alignItems: "center",
    gap: "24px",
  },
  mobile_modal_paper: {
    maxHeight: "none",
    height: "100%",
  },
  desktop_modal_paper: {
    maxWidth: "unset",
    width: "80%",
    minHeight: "300px",
  },
  manageColumnsImage: {
    marginRight: "11px",
  },
  buttonWrapper: {
    display: "flex",
    gap: "24px",
    alignItems: "center",
  },
});

export default useStyles;
