import * as React from "react";
import style from '../Style.module.css';
import moment from 'moment-timezone';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { fixCountryCode, is_empty } from "../../../../utils/validations";
import { parsePhoneNumber } from "../../../../utils/Utils";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import constants from "../../../../constants/constants";
import styles from "../../../modules/mobileCardView/mobileCard.module.css";
import { getUserCurrencySymbol, getUserTimezone } from "../../../../utils/AuthUtil";

const Messages = (props) => {

    const { data, close } = props;
    const isDesktop = useMediaQuery("(min-width: 767px)");

    const phone_number = parsePhoneNumber(data.record_data, { countryCodeKey: "country_code", phoneNumberKey: "phone_number" });

    const handleMsg = (record) => {
        if (record.reason_type === 7 && record.message.includes(';')) {
            let temp = record.message.split(';');
            temp[1] = getUserCurrencySymbol();
            record.message = temp.join(' ')
        }
        return record;
    }

    return (
        <div className={`${style.modal} alert_modal`}>
            <div className={`row ${style.bold_black_large_text}`}>
                <span className='col-md-12'>All Messages</span>
            </div>
            <div className={`row ${style.bold_black_normal_text}`}  >
                <span className='col-md-12'> {data.record_data.full_name} </span>
            </div>
            <div className={`row ${style.hint}`}  >
                <span className='col-md-12'>{is_empty(data.record_data.phone_number) || data.record_data.phone_number.length < 5 ? 'N/A' :
                    <a target="_blank" rel="noopener noreferrer" href={`https://api.whatsapp.com/send?phone=${phone_number.substring(1)}`} className={style.whatsapp_number}>
                        {phone_number} <WhatsAppIcon className={style.whatsapp_icon} />
                    </a>}
                </span>
            </div>
            {!is_empty(data.msgs_data) &&
                <>
                    {isDesktop ? <table className={`${style.table} ${style.row}`} >
                        <tr className={style.tr} >
                            <th className={style.th} >Reason</th>
                            <th className={style.th} >Message</th>
                            <th className={style.th} >Date and Time</th>
                        </tr>

                        {data.msgs_data.map(record => {
                            record = handleMsg(record);
                            return (<tr className={style.tr}>
                                <td className={`${style.td}`} >{is_empty(record.reason) ? 'N/A' : <span>{record.reason}</span>}</td>
                                <td className={`${style.td}`} >{is_empty(record.message) ? 'N/A' : <span>{record.message}</span>}</td>
                                <td className={style.td} >{is_empty(record.created_at) ? 'N/A' : <span>{moment(record.created_at).tz(getUserTimezone()).format('DD/MM/YY hh:mm A')} </span>} </td>
                            </tr>)
                        }
                        )}
                    </table> : (
                        <div>
                            {data.msgs_data.map(record => {
                                record = handleMsg(record);
                                return (<div className={styles.mobileCardcontainer}>
                                    <div>
                                        <span className={styles.itemHeading}>Reason: </span>
                                        <span className={styles.item1}>{is_empty(record.reason) ? 'N/A' : <span>{record.reason}</span>}</span>
                                    </div>
                                    <div>
                                        <span className={styles.itemHeading}>Message: </span>
                                        <span className={styles.item1}>{is_empty(record.message) ? 'N/A' : <span>{record.message}</span>}</span>
                                    </div>
                                    <div>
                                        <span className={styles.itemHeading}>Date and Time: </span>
                                        <span className={styles.item1}>
                                            {is_empty(record.created_at) ? 'N/A' : <span>{moment(record.created_at).tz(getUserTimezone()).format('DD/MM/YY hh:mm A')} </span>}
                                        </span>
                                    </div>
                                </div>)
                            }
                            )}
                        </div>
                    )
                    }
                </>}
            <div className={`row ${style.delete_title}`} onClick={() => {
                close();
            }} >
                <span className='col-md-12'> Close </span>
            </div>
        </div>
    )
}

export default Messages;