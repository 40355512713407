import React from 'react';
import {
    useNotify,
    Pagination, useListController, useRefresh
} from 'react-admin';
import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import styles from "../../../../Style.module.css";
import DatePicker from 'react-date-picker';
import { is_empty } from '../../../../utils/validations';
import dataProvider from '../../../../data/dataProvider';
import api from '../../../../data/APIs';
import MoneyField from "../../../modules/MoneyField";
import { CustomGrid } from '../../../modules/CustomGrid';
import TextFieldCustom from '../../../Fields/TextFieldCustom';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ActionFieldCustom from '../../../Fields/ActionFieldCustom';
import moment from 'moment';
import DateFieldCustom from '../../../Fields/DateFieldCustom';
import style from '../Style.module.css';
import { useNotifications } from 'utils/hooks';


const ContentTransactions = (props) => {

    const PROPS = { "basePath": `/${api.content_purchases_list}`, "history": { "length": 6, "action": "POP", "location": { "pathname": `/${api.content_purchases_list}`, "search": "", "hash": "" } }, "location": { "pathname": `/${api.content_purchases_list}`, "search": "", "hash": "" }, "match": { "path": `/${api.content_purchases_list}`, "url": `/${api.content_purchases_list}`, "isExact": true, "params": {} }, "resource": `${api.content_purchases_list}`, "options": { "label": `${api.content_purchases_list}` }, "hasList": true, "hasEdit": false, "hasShow": false, "hasCreate": false, "syncWithLocation": true };

    const { customerData } = props;
    const refetch = useRefresh();

    const [selected_record, set_selected_record] = React.useState(null);
    const [date_disabled, set_date_disabled] = React.useState(false);
    const [expiry_date, set_expiry_date] = React.useState(false);

    const isDesktop = useMediaQuery("(min-width: 767px)");
    const { data } = useListController(PROPS);
    const mapData = Object.values(data);

    const { pushNotification } = useNotifications();

    const ActionField = ({ record = {}, source }) => {
        return (<div onClick={(event) => {
            event.preventDefault();
            set_selected_record(record);
        }} className='action_text'>Change Expiry date</div>);
    }

    const changeExpiryDate = async () => {
        try {
            if (is_empty(expiry_date)) return pushNotification('Select date!', { variant: "outlined", color: "coral_red" });
            const status = await dataProvider.custom_request(api.content_expiry_update, 'POST', { booking_uuid: selected_record.booking_uuid, expiry_time: expiry_date });
            console.log("status", status);
            pushNotification(status.message, { variant: "outlined", color: "primary" });
            if (status.status === 200) {
                set_selected_record(null);
                refetch();
            }
        }
        catch (err) {
            console.log("changeExpiryDate", err);
        }
    }

    const ListPagination = props => <Pagination rowsPerPageOptions={[]} {...PROPS} />;

    return (
        <div className={isDesktop ? `` : styles.mobileParent}>
            {!is_empty(data) && <div className={`${style.table_heading}`}>Content Purchased Validity</div>}
            <CustomGrid empty={<></>} resource={api.content_purchases_list} mapData={mapData} basePath={api.content_purchases_list} props={props} ListPagination={ListPagination} exporter={false} listItem={style.listItem}>
                <TextFieldCustom label={'Content name'} sortable={false} source="session_name" emptyText={'N/A'} />
                <ActionFieldCustom sortable={false} label={'Price paid'} source="creator_sales" emptyText={'N/A'} ActionField={MoneyField} Nobutton={true} />
                <DateFieldCustom sortable={false} source="purchase_date" label={'Purchase date'} />
                <DateFieldCustom sortable={false} source="expiry_date" label={'Expiry date'} />
                <ActionFieldCustom sortable={false} label="Action" ActionField={ActionField} />
            </CustomGrid>



            {!is_empty(selected_record) && <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={true}
                onClose={() => set_selected_record(null)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <div style={{ minHeight: 340 }} className={`alert_modal`}>
                    <div
                        className={`${styles.row} ${styles.bold_black_large_text} text-center`}>
                        {'Change Expiration Date'}
                    </div>
                    <div className={`${styles.row} ${styles.alert_body} text-center`}>
                        {`Change Expiration date of ${selected_record.session_name} for ${customerData.customer_name}`}
                    </div>
                    <div className={`row bottom_margin`} style={{ justifyContent: 'center' }}>
                        <DatePicker disabled={date_disabled} dayPlaceholder="DD" format={'dd-MM-y'} monthPlaceholder="MM" yearPlaceholder="YYYY" minDate={new Date(moment().toString())} value={is_empty(expiry_date) ? '' : new Date(moment(expiry_date, ['DD-MM-YYYY']).toString())} onChange={(date) => {
                            let value = is_empty(date) ? date : moment(date).format('DD-MM-YYYY');
                            set_expiry_date(value);
                        }} className={` ${styles.price_input}`} />
                    </div>

                    <div style={{ justifyContent: 'center' }} className={`${styles.flex_row}`}>
                        <span className={`${styles.preview_cta}`} onClick={() => {
                            set_selected_record(null);
                        }} >Cancel</span>
                        <span className={`${styles.save_cta}`} onClick={() => {
                            changeExpiryDate();
                        }} >Save</span>
                    </div>
                </div>
            </Modal>}
        </div>
    );

}

export default ContentTransactions;