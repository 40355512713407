import React, { useCallback } from "react";
import { useRefresh } from "react-admin";

import style from "../../Style.module.css";

import dataProvider from "../../../../../data/dataProvider";
import api from "../../../../../data/APIs";
import { is_empty } from "../../../../../utils/validations";
import { getTableConfig, CONTENT_TRANSACTION_PROPS } from "./tableConfig";

import ExlyTable from "common/Components/ExlyTable";
import { useNotifications } from "utils/hooks";
import UpdateExpiryModal from "../components/UpdateExpiryModal/UpdateExpiryModal";
import { orgPermissions, RESOURCE_KEYS } from "utils/OrgPermissions";
import { schedule_types_ids } from "constants/schedule";

const ContentTransactions = (props) => {
  const { customerData, listingType } = props;
  const refetch = useRefresh();

  const [selected_record, set_selected_record] = React.useState(null);
  const [date_disabled] = React.useState(false);
  const hasWriteAccess = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.ALL_CUSTOMERS
  );
  const isBrandedCommunity =
    listingType === schedule_types_ids.branded_community;

  const { pushNotification } = useNotifications();

  const onChangeExpiryDate = useCallback(
    ({ record = {} }) => {
      set_selected_record(record);
    },
    [set_selected_record]
  );

  const changeExpiryDate = async (expiry_date) => {
    try {
      if (is_empty(expiry_date))
        return pushNotification("Select date!", {
          variant: "outlined",
          color: "coral_red",
        });
      const status = await dataProvider.custom_request(
        api.content_expiry_update,
        "POST",
        { booking_uuid: selected_record.booking_uuid, expiry_time: expiry_date }
      );
      pushNotification(status.message, {
        variant: "outlined",
        color: "primary",
      });
      if (status.status === 200) {
        set_selected_record(null);
        refetch();
      }
    } catch (err) {
      console.log("changeExpiryDate", err);
      pushNotification(err?.message, {
        variant: "outlined",
        color: "coral_red",
      });
    }
  };

  return (
    <>
      <ExlyTable
        ra_props={CONTENT_TRANSACTION_PROPS(listingType)}
        columnConfig={getTableConfig({
          onChangeExpiryDate: onChangeExpiryDate,
          hasWriteAccess,
          isBrandedCommunity,
        })}
        layoutProps={{
          paddingDesktop: "0",
          paddingBottom: "68px",
          layoutDesktopMargin: "20px 24px 24px 24px",
          noMobileBoxShadow: true,
          showFixedBars: true,
        }}
        fieldsLeftPadded
        borderedFields
        drawerFieldsBordered
        fieldsAlignment="space-between"
        drawerFieldsAlignment="space-between"
        recordFooterVariant="secondary"
        primaryKey="id"
        renderSecondaryAction={
          hasWriteAccess
            ? () => (
                <div className={style.mobile_table_footer_title}>Action:</div>
              )
            : null
        }
        customPrimaryAction={
          hasWriteAccess && {
            label: "Change Expiry date",
            onClick: (record) => onChangeExpiryDate({ record }),
          }
        }
      />

      {selected_record && (
        <UpdateExpiryModal
          customerName={customerData.customer_name}
          sessionName={selected_record.session_name}
          endDate={selected_record?.expiry_date ?? new Date()}
          onClose={() => set_selected_record(null)}
          onSave={changeExpiryDate}
          date_disabled={date_disabled}
          open={!is_empty(selected_record)}
        />
      )}
    </>
  );
};

export default ContentTransactions;
