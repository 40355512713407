import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  () => ({
    undo: {
      fontSize: "14px",
      color: "#493AB1",
      opacity: "0.6",
      display: "flex",
      alignItems: "center",
      gap: "7px",

      "&:hover": {
        cursor: "pointer",
      },
    },
    bodyWrapper: {
      // height: "calc(100vh - 36px)",
      display: "flex",
      justifyContent: "space-between",
      overflow: "auto",
      height: ({ isDesktop }) => isDesktop && "88vh",
    },
    tabsWrapper: {
      paddingLeft: ({ isDesktop }) => !isDesktop && "8px",
    },
    tagsDivWrapper: {
      width: "30%",
      padding: "18px",
    },

    navWrapper: {
      display: "flex",
      width: "100%",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "22px",
      alignItems: "center",
      cursor: "pointer",
      padding: "19px 15px",
      borderBottom: "3px solid #6C5DD3",
      backgroundColor: "#FFFFFF",
      color: "#000000",
      position: "sticky",
    },
    cardTitle: {
      fontSize: 16,
      paddingBottom: 8,
      fontWeight: 700,
      color: "#333",
      letterSpacing: 0.4,
      textTransform: "capitalize",
      textAlign: ({ isDesktop }) => (isDesktop ? "inherit" : "center"),
    },

    active: {
      color: "#00B779",
      display: "flex",
      alignItems: "center",
      justifyContent: ({ isDesktop }) => (isDesktop ? "inherit" : "center"),

      "& svg": {
        marginLeft: "7px",
      },
    },

    // footerBtn: {
    //   margin: "30px 17px",
    //   maxWidth: "645px",
    //   width: ({ isDesktop }) => (isDesktop ? "100%" : "90%"),
    // },

    // footerMobileBorder: {
    //   borderTop: "1px solid #E7E6E4",
    // },

    customHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "22px",
      color: "#493AB1",
      letterSpacing: "0.02em",
      font: ({ isDesktop }) =>
        isDesktop ? "16px Helvetica Neue,roboto" : "14px Helvetica Neue,roboto",
      fontWeight: "400 !important",
      "&>span": {
        marginRight: "7px",
      },
    },

    bannerWrapper: {
      margin: ({ isDesktop }) => (isDesktop ? "20px auto " : "auto"),
      padding: ({ isDesktop }) => (isDesktop ? "20px " : "10px"),

      borderRadius: "12px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      maxWidth: "455px",
      width: "100%",
      fontWeight: "700 !important",
      font: ({ isDesktop }) =>
        isDesktop ? "22px Helvetica Neue,roboto" : "18px Helvetica Neue,roboto",
    },
    modalBodyWrapper: {
      marginTop: "25px",
      marginBottom: "25px",
      // height: ({ isDesktop }) => (isDesktop ? "170px" : "155px"),
    },
    modalPaperClass: {
      minWidth: "450px !important",
    },
    // textArea: {
    //   width: "400px",
    //   height: "100px",
    // },
    navWrapperClass: {
      display: "flex",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "22px",
      alignItems: "center",
      cursor: "pointer",
      // position:"absolute",
      // top:"0",
      // left:"0",
      width: "100%",
      padding: ({ isDesktop }) =>
        isDesktop ? "12px 24px 12px 24px" : "12px 24px 12px 2px",
      borderBottom: "1.5px solid #E7E6E4",
    },
    leftDivWrapper: {
      width: ({ isDesktop }) => (isDesktop ? "70%" : "100%"),
      padding: ({ isDesktop }) => (isDesktop ? "25px" : "20px 5px"),
      borderRight: "1.5px solid #E7E6E4",
      overflowY: "auto",
    },
    tagInputWrapper: {
      border: "none",
    },

    assetWrapper: {
      width: "340px",
      display: "flex",

      marginTop: "15px",
      marginBottom: "20px",
    },

    svgWrapper: {
      width: "100%",
    },

    emptyImageWrapper: {
      marginTop: ({ isDesktop }) => (isDesktop ? "70px" : "10px"),
      marginBottom: "30px",
    },
    boldHeading: {
      fontWeight: "700",
      fontSize: "18px",
      lineHeight: "19px",
      /* identical to box height, or 106% */

      letterSpacing: "0.02em",

      color: "rgba(39, 37, 34, 0.85)",
    },
    emptyStatePara: {
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "19px",
      /* or 136% */

      letterSpacing: "0.02em",

      color: "rgba(39, 37, 34, 0.6)",
      paddingTop: "15px",
    },
    emptyResponseWrapper: {
      padding: "35px",
      width: ({ isDesktop }) => (isDesktop ? "70%" : "100%"),
    },
    notchedOutline: {
      border: `none !important`,
    },
    addTagWrapper: {
      display: "flex",
      alignItems: "center",
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
