import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { EXLY_GLOBAL__BULK_IMPORT_ID } from "redux/actions";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { is_empty } from "utils/validations";
import {
  BULK_IMPORT_AWS_CRED,
  BULK_IMPORT_BUCKET,
  BULK_IMPORT_STRIP_REFRESH_DEBOUNCE_TIME,
  bulk_import_api_type,
  download_file_max_name_length,
} from "../../../../../features/Crm/modules/BulkImport/modules/BulkImportInformation/BulkImportInformation.constant";
import { getS3SignedUrl, isRequestSuccessful } from "utils/Utils";
import {
  getBulkImportList,
  getSingleBulkImport,
  updateReportSeenFlag,
} from "../helpers/bulkImportHelpers";
import { useDebounceAction } from "utils/hooks/useDebounceAction";
import {
  notification_color_map,
  notification_variant_map,
  useNotifications,
} from "utils/hooks/useNotifications";
import { getS3ItemKey } from "utils/upload/upload.utils";
import { downloadFileWithCustomName } from "utils/download/download.utils";

const usePostBulkUpload = (props) => {
  const { type, handleClientUpload } = props;

  // bulkImportUuids contain the uuid of import of leads and
  // customers which is ongoing (if any)
  const { bulkImportUuids } = useSelector((state) => state.global);
  const contactBulkImportId = bulkImportUuids?.[type];

  const [isBulkImportProcessing, setIsBulkImportProcessing] = React.useState(
    Boolean(contactBulkImportId)
  );
  const [showBulkUploadModal, setShowBulkUploadModal] = React.useState(false);
  const [bulkUploadApiResponse, setBulkUploadApiResponse] = React.useState({});
  const [showImportAlreadyInProgress, setShowImportAlreadyInProgress] =
    React.useState(false);
  const [showBulkErrorModal, setShowBulkErrorModal] = React.useState(false);

  const isDesktop = useDesktopMediaQuery();
  const dispatch = useDispatch();
  const { pushNotification } = useNotifications();

  const handleClearBulkImport = (importType) => {
    dispatch({
      type: EXLY_GLOBAL__BULK_IMPORT_ID,
      payload: { [importType]: "" },
    });
  };

  const handleBulkUploadModalClose = () => {
    setShowBulkUploadModal((prev) => !prev);
    // if we have some contacts that have been uploaded, then we
    // refresh the screento update the data on the table
    // and get the latest contacts
    if (!is_empty(bulkUploadApiResponse?.records_added)) {
      window.location.reload();
    }
  };

  const handleShowImportAlreadyInProgressModal = () => {
    setShowImportAlreadyInProgress((prev) => !prev);
  };

  const handleShowBulkErrorModal = () => {
    setShowBulkErrorModal((prev) => !prev);
  };

  const handleRetryBulkImportClick = () => {
    if (isDesktop) {
      handleClientUpload();
    } else {
      setIsBulkImportProcessing(false);
    }
  };

  const handleDownloadReportClick = async () => {
    const reportKey = getS3ItemKey(bulkUploadApiResponse?.report_url);
    const url = await getS3SignedUrl(
      reportKey,
      BULK_IMPORT_BUCKET,
      BULK_IMPORT_AWS_CRED
    );
    if (url) {
      // Using this function will download the file with this given name
      downloadFileWithCustomName(
        url,
        `report_bulk_import_${type}s.csv`,
        download_file_max_name_length
      );
    }
  };

  const handleBulkImportStatus = async () => {
    try {
      const { data, status } = await getBulkImportList(
        bulk_import_api_type?.[type]
      );
      // Need only the first element of "page_data" here so using page_data?.[0],
      // destructuring this will only lead multiple if statements
      // for empty checks which furthur leads to cognitive complexity
      const bulkData = data?.page_data?.[0];
      if (isRequestSuccessful(status) && !is_empty(bulkData)) {
        const {
          is_completed: isCompleted,
          request_id: requestId,
          is_report_seen: isReportSeen,
          failure_reason: failureReason,
        } = bulkData;
        if (!isCompleted) {
          setBulkUploadApiResponse(bulkData);
          setIsBulkImportProcessing(true);
          return;
        } else if (!isReportSeen) {
          updateReportSeenFlag({
            request_id: requestId,
            is_report_seen: true,
          });
          if (failureReason) {
            setShowBulkErrorModal(true);
            setIsBulkImportProcessing(false);
            return;
          }
          setBulkUploadApiResponse(bulkData);
          setShowBulkUploadModal(true);
          setIsBulkImportProcessing(false);

          handleClearBulkImport(type);
        } else {
          setIsBulkImportProcessing(false);
        }
      }
    } catch (err) {
      console.log("error while fetching bulk import data: ", err);
    }
  };

  const handleSingleBulkImport = async () => {
    try {
      const { data, status } = await getSingleBulkImport(contactBulkImportId);
      if (isRequestSuccessful(status) && !is_empty(data)) {
        const {
          is_completed: isCompleted,
          is_report_seen: isReportSeen,
          failure_reason: failureReason,
        } = data;
        if (!isCompleted) {
          setBulkUploadApiResponse(data);
          setIsBulkImportProcessing(true);
          pushNotification(
            "Import in progress. We'll notify you via email when complete",
            {
              variant: notification_variant_map.outlined,
              color: notification_color_map.success,
            }
          );
          handleClearBulkImport(type);
          return;
        } else if (!isReportSeen) {
          updateReportSeenFlag({
            request_id: contactBulkImportId,
            is_report_seen: true,
          });
          if (failureReason) {
            setShowBulkErrorModal(true);
            setIsBulkImportProcessing(false);
            return;
          }
          setBulkUploadApiResponse(data);
          setShowBulkUploadModal(true);
          setIsBulkImportProcessing(false);
          handleClearBulkImport(type);
        } else {
          setIsBulkImportProcessing(false);
        }
      }
    } catch (err) {
      console.log("error while fetching single bulk import data: ", err);
    }
  };

  const handleBulkImportStripRefresh = () => {
    if (contactBulkImportId) {
      handleSingleBulkImport();
    } else {
      handleBulkImportStatus();
    }
  };

  const debouncedHandleBulkImportStripRefresh = useDebounceAction(
    handleBulkImportStripRefresh,
    BULK_IMPORT_STRIP_REFRESH_DEBOUNCE_TIME
  );

  React.useEffect(() => {
    debouncedHandleBulkImportStripRefresh();
  }, []);

  return {
    isBulkImportProcessing,
    bulkUploadApiResponse,
    showBulkUploadModal,
    showImportAlreadyInProgress,
    handleShowImportAlreadyInProgressModal,
    showBulkErrorModal,
    debouncedHandleBulkImportStripRefresh,
    bulkImportSuccessModalProps: {
      open: showBulkUploadModal,
      handleClose: handleBulkUploadModalClose,
      titleText: "Bulk import finished!",
      successMessage: `${
        bulkUploadApiResponse.records_added || 0
      } Contacts added`,
      errorMessage: `${
        bulkUploadApiResponse.records_failed || 0
      } Contacts failed`,
      warningMessage: `${
        bulkUploadApiResponse.records_skipped || 0
      } Contacts skipped`,
      handlePrimaryButtonClick: handleDownloadReportClick,
      showWarningStrip: true,
    },
    bulkImportAlreadyInProgressModalProps: {
      open: showImportAlreadyInProgress,
      handleClose: handleShowImportAlreadyInProgressModal,
      titleText: "An import is already in progress",
      buttonText: "Got it",
      handleButtonClick: handleShowImportAlreadyInProgressModal,
      messageText:
        "We will notify you via Email when the ongoing import is finished. We assure you that it won’t take very long. Until then, you won’t be able to do another import.",
    },
    bulkImportErrorModalProps: {
      open: showBulkErrorModal,
      handleClose: handleShowBulkErrorModal,
      titleText: "Something went wrong",
      buttonText: "Retry",
      handleButtonClick: handleRetryBulkImportClick,
      messageText:
        "This is unexpected. Something went wrong and your bulk import couldn't be completed. Please try again!",
    },
  };
};

export default usePostBulkUpload;
