import React from "react";
import { useListController } from "react-admin";
import IconButton from "@material-ui/core/IconButton";

import style from "../../../../../Style.module.css";
import module_style from "../../Style.module.css";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import classnames from "classnames";

import { is_empty } from "utils/validations";
import { orgPermissions, RESOURCE_KEYS } from "utils/OrgPermissions";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";

import EmptyListIcon from "assets/vectors/groupPeople.svg";

import ExlyTable from "common/Components/ExlyTable";
import { WhatsAppButton } from "webpage-leads/components/CustomColumns";

import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import SessionStorageConstants from "constants/SessionStorage.constants";

import { filterConfig, tableConfig } from "./CampaignLeadsTableConfig";
import { exporter } from "utils/csvExporters/adsCampaignLeads";
import { masterTableChipsFormatter } from "../../ManageBookings/components/MasterTableConfig";

const CampaignLeads = (props) => {
  const isDesktop = useDesktopMediaQuery();

  const record = JSON.parse(
    sessionStorage.getItem(SessionStorageConstants.AD_CAMPAIGN_DETAIL)
  );

  const { preview_image, title, leads } = record;

  const is_desktop = useDesktopMediaQuery();

  const { loaded, data, selectedIds, onSelect, filterValues, total, perPage } =
    useListController(props);
  const hasPagination = total > perPage;
  const isEmpty = loaded && is_empty(data) && is_empty(filterValues);

  const isCustomerDetailsHidden = orgPermissions.isNavigationItemHidden(
    RESOURCE_KEYS.SECTIONS.ALL_CUSTOMERS
  );

  const columnConfig = tableConfig;

  const canSendWhatsapp = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.WHATSAPP_BROADCASTS
  );
  const canSendEmail = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.CUSTOM_MAILS
  );
  const canBroadcastMessage = (canSendEmail || canSendWhatsapp) && !isEmpty;
  const showActions = canBroadcastMessage;

  const footerProps = {
    hidePrimarybtn: true,
    hideSecondaryBtn: true,
  };

  const bookingsTitle = (
    <span className={module_style.page_title}>
      <span onClick={() => window.history.go(-1)} className="pointer">
        Leads
      </span>

      <span>
        {` / `}
        <span className={module_style.action_text}>{title}</span>
      </span>
    </span>
  );

  const emptyStateProps = {
    content_title: "No leads yet.",
    empty_list_icon: EmptyListIcon,
    alt: "no participants",
    classes: { content_wrapper: module_style.listing_empty_wrapper },
  };

  return (
    <div className={module_style.listing_modal_contaner}>
      {is_desktop && (
        <div className={module_style.nav_wrap_customer_list}>
          <IconButton
            className={module_style.nav_back_btn}
            onClick={() => window.history.go(-1)}
          >
            <ArrowBackRoundedIcon />
          </IconButton>
          {bookingsTitle}
        </div>
      )}
      <div className={`component-wrapper ${module_style.leftDivWrapper}`}>
        <img className={`${module_style.offering_image}`} src={preview_image} />
        <div className={` ${module_style.listing_container}`}>
          <div id="listing_desc_container" className={``}>
            <div
              style={{ marginBottom: 5, fontWeight: 700, fontSize: 16 }}
              className={`${style.bold_black_medium_text}`}
            >
              {title}
            </div>
          </div>
        </div>
      </div>

      <div className={"component-wrapper"}>{leads} Leads</div>

      <div className={isDesktop && "component-wrapper-x"}>
        <ExlyTable
          ra_props={{
            ...props,
            exporter: orgPermissions.canExport() ? exporter : false,
          }}
          noExportButton={!orgPermissions.canExport()}
          tableTitle={!is_desktop && bookingsTitle}
          layoutProps={{
            paddingDesktop: "0",
            paddingBottom: hasPagination ? "120px" : "68px",
            layoutDesktopMargin: "20px 0px 0px 0px",
            layoutMobileMargin: "0",
            paddingMobile: "0px",
            noMobileBoxShadow: true,
          }}
          checkboxSelection
          selected={selectedIds}
          onRowSelected={onSelect}
          columnConfig={columnConfig}
          tableFilters={filterConfig}
          filter={{ is_published: true }}
          tableChipsFormatter={(filter, value) => {
            masterTableChipsFormatter({ filter, value });
          }}
          renderPrimaryColumnRightNode={(record) =>
            !isCustomerDetailsHidden && (
              <WhatsAppButton
                record={record}
                source="phone_number"
                countryCodeKey="country_code"
              />
            )
          }
          borderedFields
          fieldsLeftPadded
          drawerFieldsBordered
          fieldsAlignment="space-between"
          drawerFieldsAlignment="space-between"
          recordFooterVariant="secondary"
          hasMobileFooter={showActions}
          footerProps={footerProps}
          getRecordFooterClassName={() =>
            classnames(module_style.mobile_footer)
          }
          primaryKey="id"
          {...emptyStateProps}
        />
      </div>
    </div>
  );
};

export default withComponentLibraryTheme(CampaignLeads);
