import React, { memo } from "react";

import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import useStyles from "./crmDetail.styles";
import ExlyImage from "common/Components/ExlyImage";
import ResponseEmpty from "assets/vectors/crm/responses_empty.svg";
import classnames from "classnames";

const EmptyResponseScreen = ({ wrapperClassname }) => {
  const isDesktop = useDesktopMediaQuery();

  const classes = useStyles({ isDesktop });
  return (
    <div className={classnames(classes.emptyResponseWrapper, wrapperClassname)}>
      <div className={classes.emptyImageWrapper}>
        <ExlyImage
          src={ResponseEmpty}
          alt={"TagsEmpty"}
          width={230}
          height={230}
        />
      </div>
      <div className={classes.boldHeading}> No forms yet.</div>
      <p className={classes.emptyStatePara}>
        {" "}
        For all the different types of forms that your contact fills, the
        corresponding responses will be collected here.
      </p>
    </div>
  );
};

export default memo(EmptyResponseScreen);
