import React from "react";
import ExlyTable from "common/Components/ExlyTable";
import useStyles from "./CustomerActivityV2.styles";
import {
  getTableConfig,
  CUSTOMER_ACTIVITY_PROPS,
} from "./customerActivityTableConfig";
import { api } from "data";
import { crmConstant } from "constants/crmConstants";
import useCustomColumnsConfig from "utils/hooks/useCustomColumnsConfig";
import useOrgMemberList from "utils/hooks/useOrgMemberList";
import { bookings_section_name } from "ui/pages/schedule/BookedSession/bookedSession.constants";
import { booking_record_slug } from "constants/schedule";

const CustomerActivity = (props) => {
  const { customerData } = props;
  const classes = useStyles();
  const { orgMembers } = useOrgMemberList();

  // @DEV please update these redirections with appendURL
  const onClickViewDetails = ({ record = {} }) => {
    switch (record.status) {
      case crmConstant.logsType.BOUGHT_OFFERING:
        return (window.location.href = `${window.location.origin}/#/${api.transaction_list}?guest_name=${customerData.customer_name}&listing__title__icontains=${record.title}&custom_filter=guest_name|listing__title__icontains`);
      case crmConstant.logsType.PAYMENT_FAILED:
        return (window.location.href = `${
          window.location.origin
        }/#/${`host/v2/interest/list?status=4&custom_filter=status`}`);
      case crmConstant.logsType.GET_IN_TOUCH:
        return (window.location.href = `${
          window.location.origin
        }/#/${`host/v2/interest/list?status=0&custom_filter=status`}`);
      case crmConstant.logsType.PAYMENT_PENDING:
        return (window.location.href = `${
          window.location.origin
        }/#/${`host/v2/interest/list?status=5&custom_filter=status`}`);
      case crmConstant.logsType.SUBSCRIBED_OFFERING:
      case crmConstant.logsType.RENEWED_SUBSCRIPTION:
        return (window.location.href = `${
          window.location.origin
        }/#/${`my-bookings?guest_name=${customerData.customer_name}&listing__title__icontains=${record.title}&custom_filter=guest_name|listing__title__icontains`}`);
      default:
        return;
    }
  };
  const { appendCustomTableConfig } = useCustomColumnsConfig({
    sectionName: bookings_section_name,
    recordSlug: booking_record_slug,
  });

  const { customColumnsToRender } = appendCustomTableConfig({
    orgMembers,
    disabled: true,
  });

  return (
    <ExlyTable
      ra_props={CUSTOMER_ACTIVITY_PROPS}
      columnConfig={getTableConfig({
        onClickViewDetails,
        customColumnsToRender,
      })}
      layoutProps={{
        paddingDesktop: "0",
        paddingBottom: "68px",
        layoutDesktopMargin: "20px 24px 24px 24px",
        noMobileBoxShadow: true,
        showFixedBars: true,
      }}
      borderedFields
      fieldsLeftPadded
      drawerFieldsBordered
      fieldsAlignment="space-between"
      drawerFieldsAlignment="space-between"
      recordFooterVariant="secondary"
      renderSecondaryAction={() => (
        <div className={classes.mobile_secondary_action}>Action:</div>
      )}
      customPrimaryAction={{
        label: "View Details",
        onClick: (record) => onClickViewDetails({ record }),
      }}
      noExportButton
    />
  );
};

export default CustomerActivity;
