import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    desktopModalPaper: {
      width: "537px",
      maxWidth: "537px",
    },
    expiryContentWrap: {
      paddingTop: "16px",
      minHeight: "400px",
      fontSize: "18px",
      lineHeight: "21px",
      color: theme.palette.secondary.main,
    },
    dataLabel: {
      fontSize: "14px",
      lineHeight: "17px",
      fontWeight: 500,
      margin: "20px 0 9px 0",
    },
    datePickerPopperClassName: {
      zIndex: 10,
    }
  }),
  { name: "CreatorTool" }
);

export default useStyles;
