import React, { useState } from "react";
import { DatePicker } from "@my-scoot/component-library-legacy";

import ExlyModal from "common/Components/ExlyModal";
import useStyles from "./styles";
import { format } from "date-fns";
import { DEFAULT_DATE_MONTH_YEAR_FORMAT } from "constants/date-fns/dateTime.constant";
import moduleStyle from "./UpdateExpiryModal.module.css";

const UpdateExpiryModal = ({
  open,
  onClose,
  onSave,
  endDate,
  startDate = null,
  sessionName,
  customerName,
  showStartDate = false,
  endDateLabel = "Expiry Date",
  modalTitle,
  modalSubTitle,
}) => {
  const classes = useStyles();
  const [expiry_date, set_expiry_date] = useState(() => new Date(endDate));
  const [start_date, set_start_date] = useState(() => new Date(startDate));

  if (!open) return null;

  return (
    <ExlyModal
      open={open}
      onClose={onClose}
      title={modalTitle ?? `Change ${endDateLabel}`}
      onPrimaryBtnClick={() =>
        onSave(
          format(expiry_date, DEFAULT_DATE_MONTH_YEAR_FORMAT),
          showStartDate
            ? format(start_date, DEFAULT_DATE_MONTH_YEAR_FORMAT)
            : undefined
        )
      }
      primaryBtnText="Update"
      modal_props={{ modalPaperClassName: moduleStyle.desktopModalPaper }}
      mobile_modal_props={{ paperClassName: moduleStyle.mobileModalPaper }}
    >
      <div className={classes.expiryContentWrap}>
        <div className={classes.content}>
          <span>
            {modalSubTitle ??
              `Change ${endDateLabel.toLowerCase()} of ${sessionName} for ${customerName}`}
          </span>
        </div>

        {showStartDate && (
          <>
            <div className={`${classes.dataLabel}`}>Start Date</div>
            <DatePicker
              value={start_date}
              onChange={set_start_date}
              popperClassName={classes.datePickerPopperClassName}
              fullWidth
            />
          </>
        )}

        <div className={classes.dataLabel}>{endDateLabel}</div>
        <DatePicker
          value={expiry_date}
          onChange={set_expiry_date}
          popperClassName={classes.datePickerPopperClassName}
          fullWidth
        />
      </div>
    </ExlyModal>
  );
};

export default UpdateExpiryModal;
