import React from "react";
import { Pagination, useListController } from "react-admin";
import styles from "../../../../Style.module.css";
import api from "../../../../data/APIs";
import { CustomGrid } from "../../../modules/CustomGrid";
import TextFieldCustom from "../../../Fields/TextFieldCustom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ActionFieldCustom from "../../../Fields/ActionFieldCustom";
import style from "../Style.module.css";
import constants from "../../../../constants/constants";
import DateTimeFieldCustom from "../../../Fields/DateTimeFieldCustom";
import { getUserCurrencySymbol } from "../../../../utils/AuthUtil";
import { crmConstant } from "constants/crmConstants";

const CustomerActivity = (props) => {
  const PROPS = {
    basePath: `/${api.customer_activity}`,
    history: {
      length: 6,
      action: "POP",
      location: { pathname: `/${api.customer_activity}`, search: "", hash: "" },
    },
    location: { pathname: `/${api.customer_activity}`, search: "", hash: "" },
    match: {
      path: `/${api.customer_activity}`,
      url: `/${api.customer_activity}`,
      isExact: true,
      params: {},
    },
    resource: `${api.customer_activity}`,
    options: { label: `${api.customer_activity}` },
    hasList: true,
    hasEdit: false,
    hasShow: false,
    hasCreate: false,
    syncWithLocation: true,
  };

  const { customerData } = props;

  const isDesktop = useMediaQuery("(min-width: 767px)");
  const { data } = useListController(PROPS);
  const mapData = Object.values(data);

  const ListPagination = () => (
    <Pagination rowsPerPageOptions={[]} {...PROPS} />
  );

  const TypeField = ({ record = {}, source }) => {
    return (
      <div>
        {
          constants.schedule_type.filter(
            (item) => item.id === record[source]
          )[0].name
        }
      </div>
    );
  };

  const handleDetailClick = ({ record = {} }) => {
    switch (record.status) {
      case 1:
        return (window.location.href = `${window.location.origin}/#/${api.transaction_list}?guest_name=${customerData.customer_name}&listing__title__icontains=${record.title}&custom_filter=guest_name|listing__title__icontains`);
      case 2:
        return (window.location.href = `${
          window.location.origin
        }/#/${`host/v2/interest/list?status=4&custom_filter=status`}`);
      case 3:
        return (window.location.href = `${
          window.location.origin
        }/#/${`host/v2/interest/list?status=0&custom_filter=status`}`);
      case 4:
        return (window.location.href = `${
          window.location.origin
        }/#/${`host/v2/interest/list?status=5&custom_filter=status`}`);
      case 5:
      case 6:
        return (window.location.href = `${
          window.location.origin
        }/#/${`payments/exly/subscription/list?subscribed_user_name=${customerData.customer_name}&listing__title__icontains=${record.title}&custom_filter=subscribed_user_name|listing__title__icontains`}`);
      default:
        return;
    }
  };

  const ActivityTypeField = ({ record = {}, source }) => {
    const { creator_sales } = record || {};

    switch (record[source]) {
      case crmConstant.logsType.BOUGHT_OFFERING:
        return (
          <div>{`Made a booking/purchase of ${getUserCurrencySymbol()} ${creator_sales?.toFixed(
            2
          )}`}</div>
        );
      case crmConstant.logsType.PAYMENT_FAILED:
        return (
          <div>{`Failed payment attempt of ${getUserCurrencySymbol()} ${creator_sales?.toFixed(
            2
          )}`}</div>
        );
      case crmConstant.logsType.GET_IN_TOUCH:
        return <div>{`Wants to be contacted`}</div>;
      case crmConstant.logsType.PAYMENT_PENDING:
        return <div>{`Expressed Interest in payment`}</div>;
      case crmConstant.logsType.SUBSCRIBED_OFFERING:
        return (
          <div>{`Subscribed for ${getUserCurrencySymbol()} ${creator_sales?.toFixed(
            2
          )}`}</div>
        );
      case crmConstant.logsType.RENEWED_SUBSCRIPTION:
        return (
          <div>{`Renewed subscription, paid ${getUserCurrencySymbol()} ${creator_sales?.toFixed(
            2
          )}`}</div>
        );
      default:
        return "N/A";
    }
  };

  const ActionField = (props) => {
    return (
      <div className={`action_text`} onClick={() => handleDetailClick(props)}>
        View Details
      </div>
    );
  };

  return (
    <div className={isDesktop ? `` : styles.mobileParent}>
      <div className={`${style.table_heading}`}>Activity</div>
      <CustomGrid
        empty={<></>}
        resource={api.customer_activity}
        mapData={mapData}
        basePath={api.customer_activity}
        props={props}
        ListPagination={ListPagination}
        exporter={false}
        listItem={style.listItem}
      >
        <TextFieldCustom
          label={"Offering name"}
          sortable={false}
          source="title"
          emptyText={"N/A"}
        />
        <ActionFieldCustom
          sortable={false}
          label={"Offering type"}
          source="type"
          emptyText={"N/A"}
          ActionField={TypeField}
          Nobutton={true}
        />
        <ActionFieldCustom
          sortable={false}
          label={"Activity"}
          source="status"
          emptyText={"N/A"}
          ActionField={ActivityTypeField}
          Nobutton={true}
        />
        <DateTimeFieldCustom
          sortable={false}
          source="created_at"
          label={"Date/Time"}
        />
        <ActionFieldCustom
          sortable={false}
          label="Action"
          ActionField={ActionField}
        />
      </CustomGrid>
    </div>
  );
};

export default CustomerActivity;
