import React from "react";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";

import ExlyModal from "common/Components/ExlyModal";
import styles from "./BulkImportProgressModal.module.css";

const BulkImportProgressModal = (props) => {
  const {
    open,
    handleClose,
    titleText,
    buttonText,
    handleButtonClick,
    messageText,
  } = props;

  return (
    <ExlyModal
      title={
        <div className={styles.title_style}>
          <ReportProblemOutlinedIcon className={styles.title_icon_style} />
          <span className={styles.title_text_style}>{titleText}</span>
        </div>
      }
      open={open}
      onClose={() => handleClose()}
      onPrimaryBtnClick={handleButtonClick}
      primaryBtnText={buttonText}
      primaryBtnProps={{
        fullWidth: true,
      }}
      showSecondaryBtn={false}
    >
      <div className={styles.content_style}>{messageText}</div>
    </ExlyModal>
  );
};

export default BulkImportProgressModal;
