/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from "react";
import { useListController } from "react-admin";

import { Tabs } from "@my-scoot/component-library-legacy";
import { schedule_types_ids } from "constants/schedule";
import {
  initialTabsEmptyState,
  TABS_LABELS,
  TABS_LISTING_TYPES_MAP,
  TABS_MAP,
} from "features/Crm/modules/CustomerTransactions/constants/CustomerTransactions.constants";
import { getActivityTabs } from "features/Crm/modules/CustomerTransactions/utils/CustomerTransactions.utils";
import useCustomerBookedListingTypes from "features/Crm/modules/CustomerTransactions/utils/useCustomerBookedListingTypes";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import ExlyLoader from "ui/modules/ExlyLoader";
import { is_empty } from "../../../../utils/validations";
import ContentTransactions from "./ContentTransactionsV2/ContentTransactionsV2";
import { CUSTOMER_ACTIVITY_PROPS } from "./CustomerActivityV2/customerActivityTableConfig";
import CustomerActivity from "./CustomerActivityV2/CustomerActivityV2";
import useStyles from "./CustomerTransactionsV2.styles";
import classnames from "classnames";

const CustomerTransactionsDetails = ({ record, className }) => {
  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles({ isDesktop });

  const customerData = record || {};
  const { username } = customerData;

  const { isLoading: loadingBookedListingTypes, bookedListingTypes } =
    useCustomerBookedListingTypes(username);
  const { data: customer_activity_data, loading: loadingActivityData } =
    useListController(CUSTOMER_ACTIVITY_PROPS);

  const [isTabEmpty, setIsTabEmpty] = useState(initialTabsEmptyState);
  const [selected_tab, set_selected_tab] = useState(null);

  const tabOptions = getActivityTabs(bookedListingTypes);
  const showTabs = tabOptions.length > 0;
  const isLoading = loadingBookedListingTypes || loadingActivityData;
  const showListingTransactions =
    selected_tab === TABS_MAP.CONTENT_TRANSACTION ||
    selected_tab === TABS_MAP.COMMUNITY_ACCESS_VALIDITY;

  const onTabChange = (event, tab) => {
    set_selected_tab(tab);
  };

  useEffect(() => {
    if (document.querySelector("#CustomGrid")) {
      document
        .querySelectorAll("#CustomGrid")
        .forEach((item) => (item.firstChild.firstChild.style.display = "none"));
    }
    return () => {
      set_selected_tab(null);
      setIsTabEmpty(initialTabsEmptyState);
    };
  }, []);

  useEffect(() => {
    const is_empty_customer_activity = is_empty(customer_activity_data);

    if (isTabEmpty.activity !== is_empty_customer_activity) {
      setIsTabEmpty({
        activity: is_empty_customer_activity,
      });
    }
  }, [customer_activity_data, isTabEmpty]);

  const hasBookingsForListingType = (listingType) =>
    bookedListingTypes.some((type) => type === listingType);

  useEffect(() => {
    const handleInitSelectedTab = () => {
      if (!isTabEmpty.activity) {
        set_selected_tab(TABS_MAP.CUTOMER_ACTIVITY);
        return;
      }

      if (hasBookingsForListingType(schedule_types_ids.content)) {
        set_selected_tab(TABS_MAP.CONTENT_TRANSACTION);
        return;
      }

      if (hasBookingsForListingType(schedule_types_ids.branded_community))
        set_selected_tab(TABS_MAP.COMMUNITY_ACCESS_VALIDITY);
    };

    handleInitSelectedTab();
  }, [isTabEmpty]);

  return (
    <div
      className={classnames(
        isDesktop ? classes.details_activity_wrapper : classes.mobile_content,
        className
      )}
    >
      {isLoading ? (
        <ExlyLoader />
      ) : (
        <>
          {showTabs ? (
            <Tabs
              tabs={tabOptions}
              value={selected_tab}
              onChange={onTabChange}
              tabVariant="filled_primary"
              classes={{ scroller: classes.tabs_scroller }}
            />
          ) : (
            <div className={classes.selected_tab_title}>
              {TABS_LABELS[selected_tab]}
            </div>
          )}

          {selected_tab === TABS_MAP.CUTOMER_ACTIVITY && (
            <CustomerActivity customerData={customerData} />
          )}

          {showListingTransactions && (
            <ContentTransactions
              customerData={customerData}
              listingType={TABS_LISTING_TYPES_MAP[selected_tab]}
            />
          )}
        </>
      )}
    </div>
  );
};

export default withComponentLibraryTheme(memo(CustomerTransactionsDetails));
