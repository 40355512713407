import { dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import { bulk_import_api } from "../BulkImportInformation.apis";

export const getSingleBulkImport = async (bulkImportId) => {
  return await dataProvider.custom_request(
    `${bulk_import_api.get_single_row_import}?request_id=${bulkImportId}`,
    apiMethods.GET
  );
};

export const getBulkImportList = async (params) => {
  return await dataProvider.custom_request(
    `${bulk_import_api.bulk_import_list}?import_type=${params?.import_type}`,
    apiMethods.GET
  );
};

export const updateReportSeenFlag = async (bulkImportData) => {
  return await dataProvider.custom_request(
    bulk_import_api.update_report_seen,
    apiMethods.POST,
    bulkImportData
  );
};
