import { analyticsConstants } from "constants/analyticsConstants";
import React from "react";
import constants from "constants/constants";
import { locationMappingString } from "webpage-leads/components/CrmModals/AddCustomFilterModal/addCustomFilterModalHelpers";
import { crmConstant } from "constants/crmConstants";
import { LinkField } from "webpage-leads/components/CustomColumns";
import { sub_category } from "../AllCustomers/customerConstants";
import { dataExists, is_empty } from "utils/validations";
import { getDateInMonthDayYearTimeFormat } from "features/Common/DateFns.utils";
export const customerDetailsTabsMapping = {
  Activity: "Activity",
  Logs: "Logs",
  Details: "Details",
  Responses: "Responses",
  Tags: "Tags",
};

export const customerDetailsTabs = [
  {
    value: customerDetailsTabsMapping.Activity,
    label: customerDetailsTabsMapping.Activity,
  },
  {
    value: customerDetailsTabsMapping.Logs,
    label: customerDetailsTabsMapping.Logs,
  },
  {
    value: customerDetailsTabsMapping.Details,
    label: customerDetailsTabsMapping.Details,
  },
  {
    value: customerDetailsTabsMapping.Responses,
    label: customerDetailsTabsMapping.Responses,
  },
];

export const customerDetailsTabsMobile = [
  {
    value: customerDetailsTabsMapping.Activity,
    label: customerDetailsTabsMapping.Activity,
  },
  {
    value: customerDetailsTabsMapping.Logs,
    label: customerDetailsTabsMapping.Logs,
  },
  {
    value: customerDetailsTabsMapping.Details,
    label: customerDetailsTabsMapping.Details,
  },
  {
    value: customerDetailsTabsMapping.Responses,
    label: customerDetailsTabsMapping.Responses,
  },
  {
    value: customerDetailsTabsMapping.Tags,
    label: customerDetailsTabsMapping.Tags,
  },
];

export const leadDetailsTabs = [
  {
    value: customerDetailsTabsMapping.Logs,
    label: customerDetailsTabsMapping.Logs,
  },
  {
    value: customerDetailsTabsMapping.Details,
    label: customerDetailsTabsMapping.Details,
  },
  {
    value: customerDetailsTabsMapping.Responses,
    label: customerDetailsTabsMapping.Responses,
  },
];

export const leadDetailsTabsMobile = [
  {
    value: customerDetailsTabsMapping.Logs,
    label: customerDetailsTabsMapping.Logs,
  },
  {
    value: customerDetailsTabsMapping.Details,
    label: customerDetailsTabsMapping.Details,
  },
  {
    value: customerDetailsTabsMapping.Responses,
    label: customerDetailsTabsMapping.Responses,
  },
  {
    value: customerDetailsTabsMapping.Tags,
    label: customerDetailsTabsMapping.Tags,
  },
];

const entityRowMapping = {
  Responses: "Responses",
  Sourcelink: "Source link",
};

// TODO @DEV following utitlity function should use constants
export const customerDetailsColumnConfig = (onSeeResponseClick, bold) => [
  {
    field: "entity",
    headerName: "Field",
    valueFormatter: (row) => <div className={bold}>{row.entity}</div>,
  },
  {
    field: "data",
    headerName: "Value",
    valueFormatter: (row) => {
      if (row.entity === entityRowMapping.Responses)
        return (
          <LinkField
            onClick={() => {
              onSeeResponseClick();
            }}
            record={row}
            linkText="See Responses"
          />
        );
      if (row.entity === entityRowMapping.Sourcelink)
        return (
          <LinkField
            onClick={() => {
              window.location.href = row.data;
            }}
            record={row}
            linkText={`${row.data}`}
          />
        );
      return `${!row.data ? "N/A" : row.data == "" ? "N/A" : row.data}`;
    },

    width: "70%",
  },
];

//@DEV todo this complete screen is to removed in crmphase2 R4, so not investing anytime for now in these functions
export const parseCustomerDetails = (record) => {
  return [
    {
      entity: "Name",
      data: record.customer_name,
    },
    {
      entity: "Email",
      data: record.customer_email,
    },
    {
      entity: "Phone",
      data:
        record.country_code && record.phone_number
          ? `${record.country_code}${record.phone_number}`
          : "N/A",
    },
    {
      entity: "Total spent",
      data: dataExists(record.total_spent) ? `${record.total_spent}` : "N/A",
    },
    {
      entity: "No. of transactions",
      data: record.transactions_count,
    },
    {
      entity: "Last transaction date",
      data: getDateInMonthDayYearTimeFormat(record.last_transaction_date),
    },
    {
      entity: "Source",
      data: analyticsConstants.lead_source[record.referer_group_id - 1]?.label, //finding the lead source value and getting label
    },

    {
      entity: "Source link",
      data: record.source_link ? record.source_link : "N/A",
    },

    {
      entity: "Responses",
      data: "See responses",
    },
    {
      entity: "Country",
      data: record.country,
    },
    {
      entity: "Last Activty on",
      data: getDateInMonthDayYearTimeFormat(record.last_transaction_date),
    },
  ];
};

export const parseLeadDetails = (record, orgMembers) => {
  return [
    {
      entity: "Name",
      data: record.full_name,
    },
    {
      entity: "Email",
      data: record.email,
    },
    {
      entity: "Phone",
      data: `${record.country_code}${record.phone_number}`,
    },
    {
      entity: "Assignee",
      data: `${!is_empty(record.staff_name) ? record.staff_name : "N/A"}`,
    },
    {
      entity: "Lead status",
      data: returnFilterValue(
        {
          field: "status",
          value: [record.status],
        },
        orgMembers
      ),
    },
    {
      entity: "Last activity",
      data: `${record.reason}`,
    },
    {
      entity: "Source",
      data: analyticsConstants.lead_source[record.referer_group_id - 1]?.label, //finding the lead source value and getting label
    },

    {
      entity: "Source link",
      data: record.source_link ? record.source_link : "N/A",
    },

    {
      entity: "Responses",
      data: "See responses",
    },
    {
      entity: "Country",
      data: record.country,
    },
    {
      entity: "Added on",
      data: getDateInMonthDayYearTimeFormat(record.lead_created_at),
    },
    {
      entity: "Last activity on",
      data: getDateInMonthDayYearTimeFormat(record.updated_at),
    },
  ];
};

export const returnTabs = (isLead, isDesktop) => {
  if (isLead) return isDesktop ? leadDetailsTabs : leadDetailsTabsMobile;

  return isDesktop ? customerDetailsTabs : customerDetailsTabsMobile;
};

/**
 *
 * @param {*} filter
 * @param {*} orgMembers
 * @param {*} tagsArray
 * @returns Function to return mapped filter values
 */
export const returnFilterValue = (filter, orgMembers, tagsArray) => {
  const { filterType } = crmConstant;
  let valueToReturn = [];
  switch (filter.field) {
    case filterType.location:
      valueToReturn = filter.value.map((value) => {
        return locationMappingString[`${value}`];
      });
      break;
    case filterType.org_member:
      filter.value.forEach((value) => {
        return orgMembers?.forEach((member) => {
          if (member.org_uuid === value) {
            valueToReturn.push(member.member_name);
          }
        });
      });
      break;
    case filterType.reason_type:
      for (let i = 0; i < sub_category.lead_source.length; i++) {
        // looping through filters array and constants array and setting values when both are equal

        for (let j = 0; j < filter.value.length; j++) {
          if (filter.value[j] === sub_category.lead_source[i].value) {
            valueToReturn.push(sub_category.lead_source[i].title);
          }
        }
      }
      break;
    case filterType.status:
      Object.values(constants.LEAD_STATUS).map((status) => {
        for (let i = 0; i < filter.value.length; i++) {
          if (filter.value[i] === status.id) {
            valueToReturn.push(status.name);
          }
        }
      });
      break;
    case filterType.referer_group_id:
      // looping through filters array and constants array and setting values when both are equal
      for (let i = 0; i < analyticsConstants.lead_source.length; i++) {
        for (let j = 0; j < filter.value.length; j++) {
          if (filter.value[j] === analyticsConstants.lead_source[i].value) {
            valueToReturn.push(analyticsConstants.lead_source[i].label);
          }
        }
      }
      break;
    case filterType.tags:
      for (let i = 0; i < tagsArray.length; i++) {
        // looping through filters array and constants array and setting values when both are equal

        for (let j = 0; j < filter.value.length; j++) {
          if (filter.value[j] === tagsArray[i].value) {
            valueToReturn.push(tagsArray[i].label);
          }
        }
      }
      break;

    default:
      valueToReturn = filter.value;
  }

  return valueToReturn;
};
