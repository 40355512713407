import React, { useState, useCallback, useMemo } from "react";
import { useListController } from "react-admin";
import { Button } from "@my-scoot/component-library-legacy";
import IconButton from "@material-ui/core/IconButton";

import style from "../../../../../Style.module.css";
import module_style from "../../Style.module.css";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import classnames from "classnames";

import { is_empty } from "utils/validations";
import { orgPermissions, RESOURCE_KEYS } from "utils/OrgPermissions";
import { useNotifications, useToggleState } from "utils/hooks";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";

import EmptyListIcon from "assets/vectors/groupPeople.svg";

import ExlyTable from "common/Components/ExlyTable";
import {
  BookingQuestions,
  WhatsAppButton,
} from "webpage-leads/components/CustomColumns";

import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import SessionStorageConstants from "constants/SessionStorage.constants";

import {
  filterConfig,
  getBroadcastActions,
  masterGetTableConfigDefault,
  tableChipsFormatter,
} from "./CampaignCustomersTableConfig";
import { BroadcastModal } from "common/Components/BroadcastModal/BroadcastModal";
import { prepareRecipientList } from "../../ManageBookings/components/helpers";
import { appendUrlParams } from "utils/urlUtils";
import { useHistory } from "react-router";
import { exporter } from "utils/csvExporters/adsCampaignBookings";

const CampaignCustomers = (props) => {
  const { notify } = useNotifications();
  const isDesktop = useDesktopMediaQuery();
  const history = useHistory();

  const record = JSON.parse(
    sessionStorage.getItem(SessionStorageConstants.AD_CAMPAIGN_DETAIL)
  );

  const { preview_image, title, bookings } = record || {};

  const is_desktop = useDesktopMediaQuery();

  const { loaded, data, selectedIds, onSelect, filterValues, total, perPage } =
    useListController(props);
  const isEmptySelectedIds = is_empty(selectedIds);
  const hasPagination = total > perPage;
  const isEmpty = loaded && is_empty(data) && is_empty(filterValues);
  const [show_answer_modal, set_show_answer_modal] = useState(false);
  const [answer_modal_data, set_answer_modal_data] = useState(null);

  const [isBroadcastModalActive, showBroadcastModal, hideBroadcastModal] =
    useToggleState(false);

  const isCustomerDetailsHidden = orgPermissions.isNavigationItemHidden(
    RESOURCE_KEYS.SECTIONS.ALL_CUSTOMERS
  );

  const sendEmail = useCallback(() => {
    if (isDesktop && isEmptySelectedIds)
      return notify("No contact selected!", "error");

    prepareRecipientList(data, selectedIds);
  }, [selectedIds, data]);

  const sendWhatsappBroadcast = useCallback(() => {
    if (isEmptySelectedIds) return notify("No contact selected!", "error");

    const phoneNumberList = selectedIds.map(
      (i) => `${data[i]?.customer_country_code}${data[i]?.customer_phone}`
    );

    history.push(
      appendUrlParams("/whatsapp-broadcasts/select", {
        preselected_numbers: encodeURIComponent(phoneNumberList.join(",")),
      })
    );
  }, [selectedIds]);

  const broadcastActions = useMemo(() => {
    return getBroadcastActions({
      sendEmail,
      sendWhatsappBroadcast,
    });
  }, [sendEmail, sendWhatsappBroadcast, selectedIds, data]);

  const handleAnswersClick = useCallback(
    (record) => {
      set_answer_modal_data(record);
      set_show_answer_modal(true);
    },
    [set_answer_modal_data, set_show_answer_modal]
  );

  const getColumnConfig = () => {
    const tableConfigProps = {
      handleAnswersClick,
      isCustomerDetailsHidden,
    };
    return masterGetTableConfigDefault(tableConfigProps);
  };

  const columnConfig = useMemo(() => {
    return getColumnConfig();
  }, [handleAnswersClick]);

  const canSendWhatsapp = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.WHATSAPP_BROADCASTS
  );
  const canSendEmail = orgPermissions.hasFeatureWriteAccess(
    RESOURCE_KEYS.SECTIONS.CUSTOM_MAILS
  );
  const canBroadcastMessage = (canSendEmail || canSendWhatsapp) && !isEmpty;
  const showActions = canBroadcastMessage;

  const footerProps = {
    primarybtn: "Send Message",
    primaryClick: showBroadcastModal,
    primaryBtnProps: {
      fullWidth: true,
    },
    hidePrimarybtn: !canBroadcastMessage,
    hideSecondaryBtn: true,
  };

  const bookingsTitle = (
    <div className={module_style.topDivWrapper}>
      <span className={module_style.page_title}>
        <span onClick={() => window.history.go(-1)} className="pointer">
          Customers
        </span>

        <span>
          {` / `}
          <span className={module_style.action_text}>{title}</span>
        </span>
      </span>
      {isDesktop && (
        <div className={module_style.action_wrapper}>
          {canBroadcastMessage && (
            <Button onClick={showBroadcastModal}>Send Message</Button>
          )}
        </div>
      )}
    </div>
  );

  const emptyStateProps = {
    content_title: "No customers yet.",
    empty_list_icon: EmptyListIcon,
    alt: "no participants",
    classes: { content_wrapper: module_style.listing_empty_wrapper },
  };

  return (
    <div className={module_style.listing_modal_contaner}>
      {is_desktop && (
        <div className={module_style.nav_wrap_customer_list}>
          <IconButton
            className={module_style.nav_back_btn}
            onClick={() => window.history.go(-1)}
          >
            <ArrowBackRoundedIcon />
          </IconButton>
          {bookingsTitle}
        </div>
      )}
      <div className={`component-wrapper ${module_style.leftDivWrapper}`}>
        <img className={`${module_style.offering_image}`} src={preview_image} />

        <div className={` ${module_style.listing_container}`}>
          <div id="listing_desc_container" className={``}>
            <div
              style={{ marginBottom: 5, fontWeight: 700, fontSize: 16 }}
              className={`${style.bold_black_medium_text}`}
            >
              {title}
            </div>
          </div>
        </div>
      </div>

      <div className={"component-wrapper"}>{bookings} Participants</div>

      <div className={isDesktop && "component-wrapper-x"}>
        <ExlyTable
          ra_props={{
            ...props,
            exporter: orgPermissions.canExport() ? exporter : false,
          }}
          noExportButton={!orgPermissions.canExport()}
          tableTitle={!is_desktop && bookingsTitle}
          layoutProps={{
            paddingDesktop: "0",
            paddingBottom: hasPagination ? "120px" : "68px",
            layoutDesktopMargin: "20px 0px 0px 0px",
            layoutMobileMargin: "0",
            paddingMobile: "0px",
            noMobileBoxShadow: true,
          }}
          checkboxSelection
          selected={selectedIds}
          onRowSelected={onSelect}
          columnConfig={columnConfig}
          tableFilters={filterConfig}
          filter={{ is_published: true }}
          tableChipsFormatter={(filter, value) => {
            tableChipsFormatter(filter, value);
          }}
          renderPrimaryColumnRightNode={(record) =>
            !isCustomerDetailsHidden && (
              <WhatsAppButton
                record={record}
                source="customer_phone"
                countryCodeKey="customer_country_code"
              />
            )
          }
          borderedFields
          fieldsLeftPadded
          drawerFieldsBordered
          fieldsAlignment="space-between"
          drawerFieldsAlignment="space-between"
          recordFooterVariant="secondary"
          hasMobileFooter={showActions}
          footerProps={footerProps}
          getRecordFooterClassName={() =>
            classnames(module_style.mobile_footer)
          }
          primaryKey="id"
          {...emptyStateProps}
        />
      </div>

      {canBroadcastMessage && isBroadcastModalActive && (
        <BroadcastModal
          open={isBroadcastModalActive}
          onClose={hideBroadcastModal}
          modal_props={{ title: "Send Message" }}
          mobile_modal_props={{ header: "Select Broadcast medium" }}
          broadcastActions={broadcastActions}
        />
      )}

      {show_answer_modal && (
        <BookingQuestions
          isOpen={show_answer_modal}
          data={answer_modal_data}
          hideModal={() => {
            set_show_answer_modal(false);
          }}
          title="Booking Q&A"
        />
      )}
    </div>
  );
};

export default withComponentLibraryTheme(CampaignCustomers);
