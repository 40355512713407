import { questions_type_mapping } from "constants/customerQuestions.constants";
import { api, dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import { filterfields } from "webpage-leads/components/CrmModals/AddCustomFilterModal/addCustomFilterModalHelpers";

export const getQuestionsArray = async (selectedListing) => {
  try {
    const response = await dataProvider.custom_request(
      api.questions.get_questions,
      apiMethods.GET,
      {
        listing_uuid: selectedListing,
      }
    );
    let filteredQuestions = [];
    filteredQuestions = response?.data.listing_questions.filter((question) => {
      if (questions_type_mapping[question.type]?.hasChoices) return question;
    });

    const parsedArray = filteredQuestions.map((question) => ({
      label: question.question,
      value: question.uuid,
      choices: question.choices,
    }));

    return parsedArray;
  } catch (err) {
    console.log(err || "error while setting question");
  }
};

/**
 *
 * @returns parsed value to be used in order to display the filters
 */
export const parseCustomFilterValue = (customFiltersValue) => {
  let parsedValue = [];
  customFiltersValue.forEach((filterVal) => {
    if (filterVal.field !== filterfields.answer_to_booking_questions) {
      const localObj = {
        field: filterVal.field,
        value: filterVal.value,
        comparator: "in",
        value_type: filterVal.field,
      };

      parsedValue.push(localObj);
    } else {
      const localObj = {
        field: filterVal.field,
        value: {
          listing_uid: filterVal?.listing?.id,
          question_uid: filterVal?.question?.id,
          choices: filterVal.value,
        },
        comparator: "in",
        value_type: "json",
      };

      parsedValue.push(localObj);
    }
  });

  return parsedValue;
};
